<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Dados Pessoais</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contrataçãos</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <v-row justify="center">
        <v-col class="col-12 col-md-6">
          <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Dados titular</h2>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="col-12 col-md-6">
          <validation-observer tag="form" ref="form" class="mb-10" @submit.prevent="submit" autocomplete="off">
            <v-row>
              <v-col class="pb-0">
                <async-cep-residencial ref="asyncCepResidencial"
                :cep="cep"
                :loadingMounted="loadingMountedCep" v-slot="{ loading: loadingCep }" @success="successCep($event)" @error="this.loadingMountedCep = false">
                  <base-text-field
                    :data-test-id="'preencher-cep'"
                    :autoBlur="9"
                    rules="required|min:9"
                    inputmode="numeric"
                    type="tel"
                    id="cep"
                    name="CEP"
                    label="CEP"
                    clearable
                    v-mask="'#####-###'"
                    :disabled="desabilitaInput"
                    outlined
                    maxlength="9"
                    v-model="cep"
                    :loading="loadingCep"
                  ></base-text-field>
                </async-cep-residencial>

              <async-profissoes :key="'c_profissao-' + keyProfissao" :administradora="administradora" :cidade="endereco.cidade" :estado="endereco.estado" v-slot="{ loading: loadingProfissoes, data }">
                  <base-wrapper-dialog-list
                    :key="'d_profissao-' + keyProfissao"
                    headerTitle="Profissão"
                    :options="data"
                    label-key="nome"
                    value-key="id"
                    :loading="loadingProfissoes"
                    return-object
                    @select="selectProfissao($event)"
                  >
                    <template v-slot:activador="{ on }">
                      <base-text-field
                        rules="required"
                        readonly
                        :disabled="!endereco.cidade || !endereco.estado || desabilitaInput"
                        inputmode="text"
                        v-on="on"
                        :data-test-id="'preencher-profissao'"
                        id="profissao"
                        name="Profissão"
                        placeholder="Selecione"
                        label="Profissão"
                        outlined
                        :value="form.publicoAlvo"
                        append-icon="mdi-chevron-right"
                        :loading="loadingProfissoes"
                      ></base-text-field>
                    </template>
                  </base-wrapper-dialog-list>
                </async-profissoes>

                <async-entidades :key="'c_entidade-' + keyEntidade" :corretora="corretora" :administradora="administradora" :cidade="endereco.cidade"
                  :estado="endereco.estado" :publico-alvo="form.publicoAlvo" v-slot="{ loading: loadingEntidades, data }">
                  <base-wrapper-dialog-list
                    :key="'d_entidade-' + keyEntidade"
                    headerTitle="Entidade"
                    :options="data"
                    label-key="id"
                    value-key="id"
                    :loading="loadingEntidades"
                    return-object
                    @select="selectEntidade($event)"
                  >
                    <template v-slot:activador="{ on }">
                      <base-text-field
                        rules="required"
                        inputmode="text"
                        v-on="on"
                        readonly
                        :disabled="!endereco.cidade || !endereco.estado || !form.publicoAlvo || desabilitaInput"
                        :data-test-id="'escolher-entidade'"
                        id="entidade"
                        name="Entidade"
                        placeholder="Selecione"
                        label="Entidade"
                        outlined
                        :value="form.entidade"
                        append-icon="mdi-chevron-right"
                        :loading="loadingEntidades"
                      ></base-text-field>
                    </template>
                  </base-wrapper-dialog-list>
                </async-entidades>
               <div class="input-cpf">
                <async-cpf v-if="informaCpf" :entidade="form.entidade" :cpf="form.cpf" :loadingMounted="loadingMountedCpf" rotina="Consultar CPF do titular da proposta"
                  v-slot="{ loading: loadingCpf }" @success="successCpf($event)" @error="errorCpf">
                  <base-text-field
                    :autoBlur="14"
                    rules="required|cpf"
                    inputmode="numeric"
                    type="tel"
                    :data-test-id="'preencher-cpf'"
                    id="cpf"
                    name="CPF"
                    label="CPF"
                    :disabled="desabilitaInput || !form.entidade"
                    clearable
                    v-mask="'###.###.###-##'"
                    outlined
                    maxlength="14"
                    v-model="form.cpf"
                    :loading="loadingCpf"
                  ></base-text-field>
                  <v-btn @click="informarDtNascTitularECpf" color="primary" class="secundaryBtn--text" text v-if="!informaCpf">
                    Informar Data Nascimento
                  </v-btn>
                </async-cpf>
               </div>
                <div class="input-dt-nascimento" v-if="form.entidade === 'ANAJUSTRA'">
                  <base-text-field
                    :autoBlur="10"
                    :rules="form.entidade === 'ANAJUSTRA' ? 'required' : ''"
                    inputmode="numeric"
                    type="text"
                    :data-test-id="'preencher-matriculaFuncional'"
                    id="matriculaFuncional"
                    name="matriculaFuncional"
                    label="Matrícula Funcional"
                    clearable
                    outlined
                    v-model="form.matriculaFuncional"
                  ></base-text-field>
                </div>
                <div class="input-dt-nascimento" v-if="informaDataNascimento">
                  <base-text-field
                    :autoBlur="10"
                    :rules="informaDataNascimento ? 'date|required' : ''"
                    inputmode="numeric"
                    type="text"
                    :disabled="desabilitaInput"
                    :data-test-id="'preencher-nascimento'"
                    id="dataNascimento"
                    name="dataNascimento"
                    label="Data de nascimento"
                    clearable
                    v-mask="'##/##/####'"
                    placeholder="dd/mm/aaaa"
                    outlined
                    v-model="form.dataNascimento"
                  ></base-text-field>
                  <v-btn @click="informarDtNascTitularECpf" color="primary" class="secundaryBtn--text" text v-if="!informaCpf">
                    Informar CPF
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-4">
              <v-col>
                <div class="d-flex align-center mb-2">
                  <h2 class="primary--text subtitle-1 font-weight-bold">Dependentes</h2>
                  <v-spacer></v-spacer>
                  <v-btn :data-test-id="'add-dependente'" icon color="primary" @click.prevent="addDependente" v-if="!desabilitaInput && enabledAddDependente()">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="!form.dependentes" class="mb-5">
              <v-col>
                <span class="d-flex justify-center subtitle-2 grey--text font-weight-regular">Nenhum cadastrado</span>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <div class="d-flex align-center" v-for="(item, index) in form.dependentes" :key="item.id">
                  <base-text-field
                    :autoBlur="10"
                    :data-test-id="'dependente-dob-'+ index"
                    rules="required|date"
                    inputmode="numeric"
                    type="tel"
                    :disabled="desabilitaInput"
                    :id="`dependente_${index + 1}`"
                    :name="`Dependente ${index + 1}`"
                    :label="`Data de nascimento ${$vuetify.breakpoint.smAndUp ? 'do dependente' : 'do dep.'} ${index + 1}`"
                    clearable
                    v-mask="'##/##/####'"
                    placeholder="dd/mm/aaaa"
                    outlined
                    v-model="form.dependentes[index].dataNascimento"
                  ></base-text-field>
                  <v-btn icon class="mb-7 ml-4" color="#F44F45" @click.prevent="removeDependente(index)">
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-btn :data-test-id="'salvar-continuar-2'" large type="submit" elevation="10" block color="primary" class="secundaryBtn--text" :loading="loading">Salvar e continuar</v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AsyncCepResidencial from "@/components/AsyncCepResidencial.vue";
import AsyncEntidades from "@/components/AsyncEntidades.vue";
import AsyncProfissoes from "@/components/AsyncProfissoes.vue";
import AsyncCpf from "@/components/AsyncCpf.vue";
import propostaService from "@/services/propostaService";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { dependenteFactory } from "@/utils/factories/dependenteFactory";
import { mapActions, mapState } from "vuex";
import filters from "@/filters";

export default {
  name: "DadosPessoais",
  components: {
    BaseWrapperDialogList,
    AsyncCepResidencial,
    AsyncProfissoes,
    AsyncCpf,
    AsyncEntidades,
    BaseTextField,
  },
  data() {
    return {
      loadingMountedCep: false,
      loadingMountedCpf: false,
      loading: false,
      loadingCpf: false,
      keyProfissao: 0,
      keyEntidade: 0,
      informaCpf: true,
      isNotBirth: false,
      informaDataNascimento: false,
      cep: "",
      form: {
        cpf: "",
        dataNascimento: "",
        dependentes: [],
        enderecos: [],
        entidade: "",
        publicoAlvo: "",
        matriculaFuncional: "",
        nome: "",
        sexo: "",
        nomeMae: "",
        idade: "",
      },
      naoConsultarSerasa: false,
    };
  },
  computed: {
    ...mapState({
      dadosTitularState: (state) => state.cadastroProposta.titular,
      dadosDependentesState: (state) => state.cadastroProposta.dependentes,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      propostaState: (state) => state.cadastroProposta.proposta,
      dadosProfissionaisState: (state) => state.cadastroProposta.titular.dadosProfissionais,
    }),
    administradora() {
      return this.propostaState.administradora;
    },
    corretora() {
      return this.propostaState.corretora.cpfCnpj;
    },
    endereco() {
      if (this.form.enderecos && this.form.enderecos.length) {
        const endRes = this.form.enderecos.find(({ tipo }) => tipo.indexOf("ENDERECO_RESIDENCIAL") > -1);
        return endRes;
      }
      return {};
    },
    desabilitaInput() {
      if (!this.propostaState.status) {
        return false;
      }
      return this.propostaState.status !== "EM_DIGITACAO";
    },
  },
  watch: {
    dadosTitularState: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
    dadosDependentesState: {
      immediate: true,
      handler: "setDataDependente",
      deep: true,
    },
  },
  mounted() {
    if (this.cep) this.loadingMountedCep = true;
    if (this.cep && !this.endereco.bairro) {
      this.$refs.asyncCepResidencial.getData(filters.removeSymbols(this.cep));
    }
    if (this.form.cpf) this.loadingMountedCpf = true;
    this.setEntidadeAlterada(false);
  },
  methods: {
    ...mapActions({
      setDadosProposta: "cadastroProposta/setDadosProposta",
      setDadosTitular: "cadastroProposta/setDadosTitular",
      setDadosDependentes: "cadastroProposta/setDadosDependentes",
      setDadosProfissionais: "cadastroProposta/setDadosProfissionais",
      setVigencia: "cadastroProposta/setVigencia",
      setEntidadeAlterada: "cadastroProposta/setEntidadeAlterada",
    }),
    informarApenasDtNascTitular() {
      this.informaDataNascimento = true;
      this.informaCpf = false;
    },
    informarDtNascTitularECpf() {
      this.informaDataNascimento = true;
      this.informaCpf = true;
    },
    informarDtNascTitular() {
      this.informaDataNascimento = true;
    },
    errorCpf(error) {
      this.loadingMountedCpf = false;
      this.informaDataNascimento = true;
      this.isNotBirth = error === 'not-birth';
    },
    setData() {
      if (!this.loading) {
        if (!Object.keys(this.dadosTitularState).length) return;
        const titular = this.$cleanObserver(this.dadosTitularState);
        this.form = { ...titular };
        if (this.form.dataNascimento) {
          this.form.dataNascimento = filters.convertDateOutput(this.form.dataNascimento);
          if (this.form.cpf) {
            this.informarDtNascTitularECpf();
          } else {
            this.informarApenasDtNascTitular();
          }
        }
        this.cep = this.endereco.cep || "";
      }
    },
    setDataDependente() {
      let dependentes = this.$cleanObserver(this.dadosDependentesState);
      dependentes = Array.isArray(dependentes)
        ? dependentes.map((d) => {
          const dt = d.dataNascimento ? filters.convertDateOutput(d.dataNascimento) : "";
          d.dataNascimento = dt;
          return d;
        })
        : [];

      this.form = { ...this.form, dependentes: [...dependentes] };
    },
    addDependente() {
      if (this.enabledAddDependente()) {
        this.form.dependentes.push(dependenteFactory());
      }
    },
    enabledAddDependente() {
      return true
    },
    removeDependente(index) {
      this.form.dependentes.splice(index, 1);
    },
    async successCep(data) {
      this.loadingMountedCep = false;
      data.tipo = ["ENDERECO_RESIDENCIAL"];
      data.numero = "";
      data.complemento = "";
      this.$set(this.form, "enderecos", [data]);
      this.keyProfissao++;
    },
    async selectProfissao(data) {
      this.$set(this.form, "publicoAlvo", data.id);
      this.keyEntidade++;
    },
    async selectEntidade(data) {
      const { entidade } = this.propostaState.titular;
      if (entidade && entidade !== data.id) this.setEntidadeAlterada(true);
      else this.setEntidadeAlterada(false);
      this.$set(this.form, "entidade", data.id);
    },
    async successCpf(data) {
      this.loadingMountedCpf = false;
      this.form = { ...this.form, ...data };

      this.naoConsultarSerasa = data === 'naoConsultaSerasa';
      if (this.naoConsultarSerasa) {
        this.informaDataNascimento = true;
      }

      if (this.form.dataNascimento) {
        this.form.dataNascimento = filters.convertDateOutput(this.form.dataNascimento);
      }
    },
    setEnderecoResidencial() {
      if (this.dadosTitularState && this.dadosTitularState.enderecos && this.dadosTitularState.enderecos.length) {
        const endResState = this.dadosTitularState.enderecos.find(({ tipo }) => tipo.indexOf("ENDERECO_RESIDENCIAL") > -1);
        const endResForm = this.form.enderecos.find(({ tipo }) => tipo.indexOf("ENDERECO_RESIDENCIAL") > -1);
        const endComState = this.dadosTitularState.enderecos.find(({ tipo }) => tipo.indexOf("ENDERECO_COMERCIAL") > -1);
        this.form.enderecos = [];
        if (endResState && endResForm && endResState.cep === endResForm.cep) {
          if (endResForm.bairro && endResState.bairro !== endResForm.bairro) {
            this.form.enderecos.push(endResForm);
          } else {
            this.form.enderecos.push(endResState);
          }
        } else {
          this.form.enderecos.push(endResForm);
        }
        if (endComState) {
          this.form.enderecos.push(endComState);
        }
      }
    },
    setarDadosProfissionais() {
      if (this.form && this.form.dadosProfissionais && Object.keys(this.form.dadosProfissionais).length) {
        this.setDadosProfissionais({ ...this.form.dadosProfissionais });
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      if (this.form && !this.form.dataNascimento) {
        this.errorCpf('not-birth');
        return;
      }
      this.setEnderecoResidencial();
      const titular = JSON.parse(JSON.stringify(this.form));
      let dependentes = JSON.parse(JSON.stringify(this.form.dependentes));
      dependentes = Array.isArray(this.form.dependentes)
        ? this.form.dependentes.map((d) => {
          d.dataNascimento = d.dataNascimento ? filters.convertDateInput(d.dataNascimento) : "";
          return d;
        })
        : [];
      if (titular.dataNascimento) {
        titular.dataNascimento = filters.convertDateInput(this.form.dataNascimento);
      }
      delete titular.dependentes;
      titular.cpf = filters.removeSymbols(titular.cpf);

      this.setDadosDependentes(this.form.dependentes);

      if (this.blnContinuarState.isEditing) {
        this.setDadosTitular({ ...titular });
        this.setDadosProposta({ ...this.propostaState });
      } else {
        this.setDadosTitular({
          ...titular,
          enderecos: this.form.enderecos,
        });
        this.setDadosProposta({
          ...this.propostaState,
          etapa: "operadora",
          nrProposta: this.propostaState.nrProposta || "",
        });
      }

      try {
        this.loading = true;
        await this.salvarDados();
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      } finally {
        this.loading = false;
      }
    },
    async salvarDados() {
      let data = {};

      this.setarDadosProfissionais();
      if (this.propostaState.nrProposta) {
        [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);

        if (data && data.guid) {
          this.setDadosTitular({
            ...this.dadosTitularState,
            pisPasep: (data && data.titular && data.titular.pisPasep) || "",
            guid: data.titular.guid,
          });

          const deps = data.dependentes.map(({ parentesco, ...resto }) => ({
            ...resto,
            parentesco: (parentesco && parentesco.nome) || "",
          }));
          this.setDadosDependentes(deps);
          this.setVigencia({
            ...data.vigencia,
          });
          this.setDadosProposta({
            ...this.propostaState,
            guid: data.guid,
            status: data.status,
            vigencia: data.vigencia,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}

.input-dt-nascimento, .input-cpf {
    position: relative;
    padding-bottom: 8px;
    .v-btn{
      position: absolute;
      right: 0;
      top: 55px;
    }
}
</style>
