/* eslint-disable consistent-return */
<template>
  <div>
    <v-dialog
      :retain-focus="false"
      no-click-animation
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <!-- <keep-alive> -->
        <component
          ref="component"
          :is="pages[step].component"
          :returning="returning"
          @next="nextStep"
          @back="checkBackStep"
          @gotodadosproposta="goToDadosProposta"
          @completed="submit"
          @openmenu="menu = true"
          @verProposta="resumo"
        ></component>
        <!-- </keep-alive> -->
      </v-card>
    </v-dialog>

    <v-navigation-drawer v-model="menu" fixed right temporary width="330">
      <v-card tile dark color="primary">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-center pa-3"
              >Contratação</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <div class="scrollbar">
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Número</b> <br />
            {{ dados.nrProposta || "--" }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Valor total</b> <br />
            {{ dados.valorTotalProposta | currency }}
          </v-col>
          <v-col
            v-if="
              dados.produtosOpcionais != null &&
              dados.produtosOpcionais.length > 0
            "
          >
            <v-col class="pa-0">
              <b>Valor total opcional</b> <br />
              {{ somarValorTotalOpcional }}
            </v-col>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters class="pa-3">
          <v-col cols="12">
            <b>Plano selecionado</b> <br />
            {{
              (dados.plano &&
                (dados.plano.nomeAns || dados.plano.nomePlanoAns)) ||
              "--"
            }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="12">
            <b>Planos opcionais selecionados</b> <br />
            <fragment
              v-if="
                dados.produtosOpcionais && dados.produtosOpcionais.length > 0
              "
            >
              <span
                v-for="(produtoOpcional, index) in dados.produtosOpcionais"
                :key="index"
              >
                {{ produtoOpcional.nomePlanoAns }} <br />
              </span>
            </fragment>
            <fragment v-else>
              <span>--</span>
            </fragment>
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="12">
            <b>Entidade</b> <br />
            {{ (dados.titular && dados.titular.entidade) || "--" }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="12">
            <b>Operadora</b> <br />
            {{ (dados.plano && dados.plano.nomeExibicaoOperadora) || "--" }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="12">
            <b>Vigência</b> <br />
            {{ dados.dataVigencia || "--" }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="12">
            <b>Qtd. beneficiário</b> <br />
            {{ (dados.dependentes && dados.dependentes.length + 1) || 0 }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <template v-slot:append>
        <v-list
          tile
          dark
          elevation="6"
          class="pa-0"
          color="red darken-4"
          style="border-radius: 0"
        >
          <v-list-item link class="pt-2 pb-2" @click="leave">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 mt-1"
                >Voltar para home</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import PreventRealodOnEditing from "@/mixins/preventReloadOnEditing";
import TheTimelineSteps from "@/components/TheTimelineSteps.vue";
import filters from "@/filters/index";
import catalogoService from "@/services/catalogoService";
import DadosPessoais from "./DadosPessoais.vue";
import Corretoras from "./Corretoras.vue";
import Operadora from "./Operadora.vue";
import Planos from "./Planos/index.vue";
import Vigencias from "./Vigencias.vue";
import DadosTitular from "./DadosTitular.vue";
import DadosProfissionais from "./DadosProfissionais.vue";
import DadosDependentes from "./DadosDependentes/index.vue";
import DadosFinanceiros from "./DadosFinanceiros.vue";
import Documentos from "./Documentos/index.vue";
import DadosResponsavelLegal from "./DadosResponsavelLegal.vue";
import DadosProdutosOpcionais from "./ProdutosOpcionais/index.vue";
import DadosPortabilidade from "./DadosPortabilidade.vue";
import DadosFiliacao from "./DadosFiliacao.vue";

export default {
  components: {
    TheTimelineSteps,
  },
  mixins: [PreventRealodOnEditing],
  data() {
    return {
      pages: [
        { nome: "Associar Corretora", component: Corretoras }, // STEP 0
        { nome: "Dados Pessoais", component: DadosPessoais }, // STEP 1
        { nome: "Seleção operadora", component: Operadora }, // STEP 2
        { nome: "Vigencias", component: Vigencias }, // STEP 3
        { nome: "Seleção plano", component: Planos }, // STEP 4
        { nome: "DadosTitular", component: DadosTitular }, // STEP 5
        { nome: "Dados Responsavel Legal", component: DadosResponsavelLegal }, // STEP 6
        { nome: "DadosProfissionais", component: DadosProfissionais }, // STEP 7
        { nome: "DadosFiliacao", component: DadosFiliacao }, // STEP 8
        { nome: "DadosDependentes", component: DadosDependentes }, // STEP 9
        { nome: "DadosPortabilidade", component: DadosPortabilidade }, // STEP 10
        { nome: "DadosProdutosOpcionais", component: DadosProdutosOpcionais }, // STEP 11
        { nome: "DadosFinanceiros", component: DadosFinanceiros }, // STEP 12
        { nome: "Documentos", component: Documentos }, // STEP 13
      ],
      tela: {
        corretora: 0,
        dadoPessoal: 1,
        operadora: 2,
        vigencia: 3,
        plano: 4,
        titular: 5,
        responsavelLegal: 6,
        profissional: 7,
        filiacao: 8,
        dependente: 9,
        portabilidade: 10,
        produtoOpcional: 11,
        financeiro: 12,
        documento: 13,
      },
      step: 0,
      currentStep: 0,
      dialog: true,
      menu: false,
      dados: {},
      returning: false,
    };
  },
  watch: {
    step(newValue) {
      if (newValue < this.currentStep) return;
      this.currentStep = newValue;
    },
    propostaState: {
      immediate: true,
      handler: "setProposta",
      deep: true,
    },
  },
  computed: {
    ...mapState({
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      propostaState: (state) => state.cadastroProposta.proposta,
      entidadeState: (state) => state.cadastroProposta.titular.entidade,
    }),
    somarValorTotalOpcional() {
      if (
        this.dados.produtosOpcionais != null &&
        this.dados.produtosOpcionais.length > 0
      ) {
        const symbol = "R$ ";
        const result = this.dados.produtosOpcionais
          .reduce((soma, item) => soma + item.valorTotal, 0)
          .toFixed(2);
        return symbol.concat(result.toString()).replace(".", ",");
      }
      return "--";
    },
  },
  created() {
    this.resetProposta();
  },
  methods: {
    ...mapActions({
      setDadosProposta: "cadastroProposta/setDadosProposta",
      resetDadosProposta: "cadastroProposta/resetDadosProposta",
      vigenciaState: (state) => state.cadastroProposta.vigencia,
    }),
    setProposta() {
      this.dados = {
        ...this.propostaState,
      };
      if (this.dados && this.dados.dataVigencia) {
        this.dados.dataVigencia = filters.convertDateOutput(
          this.dados.dataVigencia
        );
      }
    },
    async nextStep(options) {
      this.setDadosProposta({ ...this.propostaState });

      const tela = JSON.parse(JSON.stringify(this.tela));
      // Se precisar ir para uma página específica sem pilha de histórico
      if (options && options.specificStep) {
        this.step = options.specificStep;
        this.returning = false;
        return;
      }

      if(options && options.fluxoLoja && !this.blnContinuarState.isEditing && !this.blnContinuarState.isEditingPlan) {
        this.step++;
      }

      const isEditingRespLegal =
        this.blnContinuarState.isEditingRL && [tela.corretora, tela.responsavelLegal].includes(this.step);
      if (
        (this.blnContinuarState.isEditing && ![tela.plano].includes(this.step)) ||
        (isEditingRespLegal && !this.blnContinuarState.isEditingPlan)
      ) {
        this.goToDadosProposta();
        return;
      }

      const isEditingPlanoPropostaDevolvida =
        this.blnContinuarState.isEditingPlan &&
        this.propostaState.status === "DEVOLVIDA";
       const isEditingVigencia =
        this.blnContinuarState.isEditingVigencia;
      if (isEditingPlanoPropostaDevolvida || isEditingVigencia) {
        if (
          this.step === tela.plano &&
          !(await this.checkParentescoResponsavelLegal())
        ) {
          this.step = tela.responsavelLegal;
          return;
        }
        if (
          [tela.plano, tela.responsavelLegal].includes(this.step) &&
          !(await this.checkParentescoDependente())
        ) {
          this.step = tela.dependente;
          return;
        }
        // inserção da tela de opcionais no fluxo de devolução ou edição de vigência
        if ([tela.plano, tela.responsavelLegal, tela.dependente].includes(this.step) && (this.propostaState.possuiPortabilidade &&
            this.propostaState.portabilidadePorBeneficiario)) {
          this.step = tela.portabilidade;
          return;
        }
        this.goToDadosProposta();
        return;
      }
      if (this.step <= this.pages.length) {
        let setStep = false; // Se true, não realiza incremento no final

        /** regra etapa dados filiacao */
        if (
          this.step === tela.profissional &&
          this.entidadeState &&
          this.$store.getters.getFiliacaoEntidadesPermitidas != null &&
          !this.$store.getters.getFiliacaoEntidadesPermitidas.includes(
            this.entidadeState.toUpperCase()
          )
        ) {
          this.step += tela.dependente - this.step; // pula para etapa 9
          setStep = true;
        } else if (
          this.step === tela.dependente /** regra etapa dados portabilidade */ &&
          (!this.propostaState.possuiPortabilidade ||
            !this.propostaState.portabilidadePorBeneficiario)
        ) {
          this.step += tela.produtoOpcional - this.step; // pula para etapa 11
          setStep = true;
        }

        /** regra etapa produtos opcionais */
        if (
          (this.step === tela.portabilidade || (this.step === tela.produtoOpcional && setStep)) &&
          this.propostaState.plano.possuiPlanosOpcionais !== null &&
          this.propostaState.plano.possuiPlanosOpcionais === false
        ) {
          this.step += tela.financeiro - this.step; // pula para etapa 12
          setStep = true;
        }

        if (!setStep) {
          this.step++;
        }
      }
      this.returning = false;
    },
    backStep() {
      if (this.step > 0) {
        if (
          this.step === 12 &&
          this.propostaState.plano.possuiPlanosOpcionais !== null &&
          this.propostaState.plano.possuiPlanosOpcionais === false
        ) {
          this.step -= 2;
        }
        if (
          this.step === 9 &&
          this.entidadeState &&
          this.$store.getters.getFiliacaoEntidadesPermitidas != null &&
          !this.$store.getters.getFiliacaoEntidadesPermitidas.includes(
            this.entidadeState.toUpperCase()
          )
        ) {
          const pulaEtapas = this.step - 7; // logica para pular para a etapa 7 (profissao)
          this.step -= pulaEtapas;
        } else if (
          (this.step === 10 || this.step === 11) &&
          (!this.propostaState.possuiPortabilidade ||
            !this.propostaState.portabilidadePorBeneficiario)
        ) {
          const pulaEtapas = this.step - 9; // logica para pular para a etapa 9 (dependentes)
          this.step -= pulaEtapas;
        } else {
          this.step--;
        }
      } else {
        this.$router.go(-1);
      }
    },
    changeStep(step) {
      this.step = step;
      this.menu = false;
    },
    leave() {
      this.menu = false;
      this.isEditing = false;
      this.$router.push("/");
    },
    resumo(guid) {
      this.isEditing = false;
      this.$router.push({ name: "areaLogada.dadosProposta", params: { guid } });
    },
    submit() {},
    resetProposta() {
      if (this.blnContinuarState.flag) return;
      this.resetDadosProposta();
      this.setDadosProposta({
        titular: {},
        dependentes: [],
        corretora: {},
        plano: {},
        dataVigencia: "",
        dadosProfissionais: {},
        dadosComplementaresFiliacao: {},
        pagamento: {},
        produtosOpcionais: [],
      });
    },
    checkBackStep() {
      const { page, flag } = this.blnContinuarState;
      if (this.step === page && flag) {
        this.menu = false;
        this.isEditing = false;
        const { guid } = this.propostaState;
        this.$router.push({
          name: "areaLogada.dadosProposta",
          params: { guid },
        });
      } else {
        this.returning = true;
        this.backStep();
      }
    },
    goToDadosProposta() {
      this.menu = false;
      this.isEditing = false;
      const { guid } = this.propostaState;
      this.$router.push({ name: "areaLogada.dadosProposta", params: { guid } });
    },
    async loadParentescoResponsavel() {
      try {
        const { data } = await catalogoService.getGrauParentescoResponsavel({
          idProdutoFatura:
            this.propostaState.plano &&
            this.propostaState.plano.idProdutoFatura,
          publicoAlvoTitular: this.propostaState.titular && this.propostaState.titular.publicoAlvo,
        });
        return data.data;
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      }
      return null;
    },
    async loadParentescoDependente() {
      try {
        const { data } = await catalogoService.getGrauParentesco({
          idProdutoFatura:
            this.propostaState.plano &&
            this.propostaState.plano.idProdutoFatura,
          estadoCivil:
            this.propostaState.titular &&
            this.propostaState.titular.estadoCivil,
          dataVigencia: this.vigenciaState && this.vigenciaState.dataVigencia,
          publicoAlvoTitular: this.propostaState.titular && this.propostaState.titular.publicoAlvo,
          dataNascimentoTitular: this.propostaState.titular && this.propostaState.titular.dataNascimento,
        });
        return data.data;
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      }
      return null;
    },
    async checkParentescoResponsavelLegal() {
      // Verifica se existe responsável legal
      if (
        !(
          this.propostaState.responsavelLegal &&
          Object.keys(this.propostaState.responsavelLegal).length
        )
      ) {
        return true;
      }
      // Caso exista, verifica se o grau de parentesco está disponível no novo plano selecionado
      const idadeTitular = filters.getAge(
        new Date(this.propostaState.titular.dataNascimento)
      );
      let grausParentesco = await this.loadParentescoResponsavel();
      grausParentesco = grausParentesco
        .filter(
          (d) => idadeTitular >= d.idadeMinima && idadeTitular <= d.idadeMaxima
        )
        .map(({ grauParentesco }) => grauParentesco);
      const parentescosFiltrado = [];
      grausParentesco.forEach((item) => {
        item.forEach((i) => parentescosFiltrado.push({ grauParentesco: i }));
      });
      return parentescosFiltrado.some(
        (item) =>
          item.grauParentesco === this.propostaState.responsavelLegal.parentesco
      );
    },
    async checkParentescoDependente() {
      // Verifica se existe dependente
      if (
        !(
          this.propostaState.dependentes &&
          this.propostaState.dependentes.length
        )
      ) {
        return true;
      }
      // Caso exista, verifica se o grau de parentesco está disponível no novo plano selecionado
      const grausParentesco = await this.loadParentescoDependente();
      for (let i = 0; i < this.propostaState.dependentes.length; i++) {
        const dependente = this.propostaState.dependentes[i];
        const idade = filters.getAge(new Date(dependente.dataNascimento));
        const parentescos = grausParentesco.filter(
          (d) => idade >= d.idadeMinima && idade <= d.idadeMaxima
        );
        if (
          !parentescos.some(
            (item) => item.grauParentesco === dependente.parentesco
          )
        ) {
          return false;
        }
      }
      return true;
    },
    changePosition(array, pos1, pos2) {
      array.splice(pos2, 0, array.splice(pos1, 1)[0]);
      return array;
    }
  },
  mounted() {
    this.isEditing = true;
    this.step = (this.blnContinuarState && this.blnContinuarState.page) || 0;
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (this.step) {
        this.backStep();
        next(false);
        return;
      }
    }

    /**
     * Mudo o valor dialog para false, já que a tela de cadastro de proposta é um modal.
     * O setTimeout serve para esperar a animação do modal temrinar para trocar de página.
     */
    this.dialog = false;
    setTimeout(() => next(), 300);
  },
};
</script>

<style scoped lang="scss">
.v-dialog__content.v-dialog__content--active {
  z-index: 6 !important;
}

.scrollbar {
  width: 100%;
  height: calc(100% - 150px);
  position: fixed;
  overflow-y: scroll;
}
</style>
