var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados Pessoais")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados contrataçãos")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "col-12 col-md-6" },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "primary--text mb-2 subtitle-1 font-weight-bold",
                    },
                    [_vm._v("Dados titular")]
                  ),
                  _c("v-divider"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "col-12 col-md-6" },
                [
                  _c(
                    "validation-observer",
                    {
                      ref: "form",
                      staticClass: "mb-10",
                      attrs: { tag: "form", autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("async-cep-residencial", {
                                ref: "asyncCepResidencial",
                                attrs: {
                                  cep: _vm.cep,
                                  loadingMounted: _vm.loadingMountedCep,
                                },
                                on: {
                                  success: function ($event) {
                                    return _vm.successCep($event)
                                  },
                                  error: function ($event) {
                                    this.loadingMountedCep = false
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ loading: loadingCep }) {
                                      return [
                                        _c("base-text-field", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "#####-###",
                                              expression: "'#####-###'",
                                            },
                                          ],
                                          attrs: {
                                            "data-test-id": "preencher-cep",
                                            autoBlur: 9,
                                            rules: "required|min:9",
                                            inputmode: "numeric",
                                            type: "tel",
                                            id: "cep",
                                            name: "CEP",
                                            label: "CEP",
                                            clearable: "",
                                            disabled: _vm.desabilitaInput,
                                            outlined: "",
                                            maxlength: "9",
                                            loading: loadingCep,
                                          },
                                          model: {
                                            value: _vm.cep,
                                            callback: function ($$v) {
                                              _vm.cep = $$v
                                            },
                                            expression: "cep",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("async-profissoes", {
                                key: "c_profissao-" + _vm.keyProfissao,
                                attrs: {
                                  administradora: _vm.administradora,
                                  cidade: _vm.endereco.cidade,
                                  estado: _vm.endereco.estado,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({
                                      loading: loadingProfissoes,
                                      data,
                                    }) {
                                      return [
                                        _c("base-wrapper-dialog-list", {
                                          key:
                                            "d_profissao-" + _vm.keyProfissao,
                                          attrs: {
                                            headerTitle: "Profissão",
                                            options: data,
                                            "label-key": "nome",
                                            "value-key": "id",
                                            loading: loadingProfissoes,
                                            "return-object": "",
                                          },
                                          on: {
                                            select: function ($event) {
                                              return _vm.selectProfissao($event)
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activador",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "base-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            rules: "required",
                                                            readonly: "",
                                                            disabled:
                                                              !_vm.endereco
                                                                .cidade ||
                                                              !_vm.endereco
                                                                .estado ||
                                                              _vm.desabilitaInput,
                                                            inputmode: "text",
                                                            "data-test-id":
                                                              "preencher-profissao",
                                                            id: "profissao",
                                                            name: "Profissão",
                                                            placeholder:
                                                              "Selecione",
                                                            label: "Profissão",
                                                            outlined: "",
                                                            value:
                                                              _vm.form
                                                                .publicoAlvo,
                                                            "append-icon":
                                                              "mdi-chevron-right",
                                                            loading:
                                                              loadingProfissoes,
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("async-entidades", {
                                key: "c_entidade-" + _vm.keyEntidade,
                                attrs: {
                                  corretora: _vm.corretora,
                                  administradora: _vm.administradora,
                                  cidade: _vm.endereco.cidade,
                                  estado: _vm.endereco.estado,
                                  "publico-alvo": _vm.form.publicoAlvo,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({
                                      loading: loadingEntidades,
                                      data,
                                    }) {
                                      return [
                                        _c("base-wrapper-dialog-list", {
                                          key: "d_entidade-" + _vm.keyEntidade,
                                          attrs: {
                                            headerTitle: "Entidade",
                                            options: data,
                                            "label-key": "id",
                                            "value-key": "id",
                                            loading: loadingEntidades,
                                            "return-object": "",
                                          },
                                          on: {
                                            select: function ($event) {
                                              return _vm.selectEntidade($event)
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activador",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "base-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            rules: "required",
                                                            inputmode: "text",
                                                            readonly: "",
                                                            disabled:
                                                              !_vm.endereco
                                                                .cidade ||
                                                              !_vm.endereco
                                                                .estado ||
                                                              !_vm.form
                                                                .publicoAlvo ||
                                                              _vm.desabilitaInput,
                                                            "data-test-id":
                                                              "escolher-entidade",
                                                            id: "entidade",
                                                            name: "Entidade",
                                                            placeholder:
                                                              "Selecione",
                                                            label: "Entidade",
                                                            outlined: "",
                                                            value:
                                                              _vm.form.entidade,
                                                            "append-icon":
                                                              "mdi-chevron-right",
                                                            loading:
                                                              loadingEntidades,
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c(
                                "div",
                                { staticClass: "input-cpf" },
                                [
                                  _vm.informaCpf
                                    ? _c("async-cpf", {
                                        attrs: {
                                          entidade: _vm.form.entidade,
                                          cpf: _vm.form.cpf,
                                          loadingMounted: _vm.loadingMountedCpf,
                                          rotina:
                                            "Consultar CPF do titular da proposta",
                                        },
                                        on: {
                                          success: function ($event) {
                                            return _vm.successCpf($event)
                                          },
                                          error: _vm.errorCpf,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                loading: loadingCpf,
                                              }) {
                                                return [
                                                  _c("base-text-field", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: "###.###.###-##",
                                                        expression:
                                                          "'###.###.###-##'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      autoBlur: 14,
                                                      rules: "required|cpf",
                                                      inputmode: "numeric",
                                                      type: "tel",
                                                      "data-test-id":
                                                        "preencher-cpf",
                                                      id: "cpf",
                                                      name: "CPF",
                                                      label: "CPF",
                                                      disabled:
                                                        _vm.desabilitaInput ||
                                                        !_vm.form.entidade,
                                                      clearable: "",
                                                      outlined: "",
                                                      maxlength: "14",
                                                      loading: loadingCpf,
                                                    },
                                                    model: {
                                                      value: _vm.form.cpf,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "cpf",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.cpf",
                                                    },
                                                  }),
                                                  !_vm.informaCpf
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "secundaryBtn--text",
                                                          attrs: {
                                                            color: "primary",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.informarDtNascTitularECpf,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Informar Data Nascimento "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          395425667
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.form.entidade === "ANAJUSTRA"
                                ? _c(
                                    "div",
                                    { staticClass: "input-dt-nascimento" },
                                    [
                                      _c("base-text-field", {
                                        attrs: {
                                          autoBlur: 10,
                                          rules:
                                            _vm.form.entidade === "ANAJUSTRA"
                                              ? "required"
                                              : "",
                                          inputmode: "numeric",
                                          type: "text",
                                          "data-test-id":
                                            "preencher-matriculaFuncional",
                                          id: "matriculaFuncional",
                                          name: "matriculaFuncional",
                                          label: "Matrícula Funcional",
                                          clearable: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.form.matriculaFuncional,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "matriculaFuncional",
                                              $$v
                                            )
                                          },
                                          expression: "form.matriculaFuncional",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.informaDataNascimento
                                ? _c(
                                    "div",
                                    { staticClass: "input-dt-nascimento" },
                                    [
                                      _c("base-text-field", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##/##/####",
                                            expression: "'##/##/####'",
                                          },
                                        ],
                                        attrs: {
                                          autoBlur: 10,
                                          rules: _vm.informaDataNascimento
                                            ? "date|required"
                                            : "",
                                          inputmode: "numeric",
                                          type: "text",
                                          disabled: _vm.desabilitaInput,
                                          "data-test-id":
                                            "preencher-nascimento",
                                          id: "dataNascimento",
                                          name: "dataNascimento",
                                          label: "Data de nascimento",
                                          clearable: "",
                                          placeholder: "dd/mm/aaaa",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.form.dataNascimento,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "dataNascimento",
                                              $$v
                                            )
                                          },
                                          expression: "form.dataNascimento",
                                        },
                                      }),
                                      !_vm.informaCpf
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "secundaryBtn--text",
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click:
                                                  _vm.informarDtNascTitularECpf,
                                              },
                                            },
                                            [_vm._v(" Informar CPF ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center mb-2" },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "primary--text subtitle-1 font-weight-bold",
                                    },
                                    [_vm._v("Dependentes")]
                                  ),
                                  _c("v-spacer"),
                                  !_vm.desabilitaInput &&
                                  _vm.enabledAddDependente()
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "data-test-id": "add-dependente",
                                            icon: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.addDependente.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-plus-circle"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-divider"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.form.dependentes
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mb-5",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-col", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-flex justify-center subtitle-2 grey--text font-weight-regular",
                                  },
                                  [_vm._v("Nenhum cadastrado")]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                _vm._l(
                                  _vm.form.dependentes,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass: "d-flex align-center",
                                      },
                                      [
                                        _c("base-text-field", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "##/##/####",
                                              expression: "'##/##/####'",
                                            },
                                          ],
                                          attrs: {
                                            autoBlur: 10,
                                            "data-test-id":
                                              "dependente-dob-" + index,
                                            rules: "required|date",
                                            inputmode: "numeric",
                                            type: "tel",
                                            disabled: _vm.desabilitaInput,
                                            id: `dependente_${index + 1}`,
                                            name: `Dependente ${index + 1}`,
                                            label: `Data de nascimento ${
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? "do dependente"
                                                : "do dep."
                                            } ${index + 1}`,
                                            clearable: "",
                                            placeholder: "dd/mm/aaaa",
                                            outlined: "",
                                          },
                                          model: {
                                            value:
                                              _vm.form.dependentes[index]
                                                .dataNascimento,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.dependentes[index],
                                                "dataNascimento",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.dependentes[index].dataNascimento",
                                          },
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mb-7 ml-4",
                                            attrs: {
                                              icon: "",
                                              color: "#F44F45",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeDependente(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-minus-circle"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secundaryBtn--text",
                                  attrs: {
                                    "data-test-id": "salvar-continuar-2",
                                    large: "",
                                    type: "submit",
                                    elevation: "10",
                                    block: "",
                                    color: "primary",
                                    loading: _vm.loading,
                                  },
                                },
                                [_vm._v("Salvar e continuar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }