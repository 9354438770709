<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1
              class="title font-weight-bold mb-1 title"
              style="line-height: 1"
            >
              Dados titular
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Dados contratação
            </h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular
          indeterminate
          color="grey"
          size="50"
        ></v-progress-circular>
      </div>
      <v-row v-else justify="center">
        <v-col class="col-12 col-md-6">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <span class="caption font-weight-bold primary--text"
                  >* Campos obrigatórios</span
                >
              </div>
              <validation-observer
                ref="form"
                tag="form"
                @submit.prevent="submit"
                autocomplete="off"
              >
                <v-expansion-panels tile flat class="accordion" multiple>
                  <!-- DADOS DO TITULAR -->
                  <validation-observer
                    ref="dadosTitular"
                    slim
                    v-slot="{ valid, failed }"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :data-test-id="'expandir-dados-titular'"
                        class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3"
                        style="min-height: auto; justify-content: space-between"
                      >
                        <div
                          :data-test-id="'expandir-dados-titular'"
                          class="d-flex align-center"
                        >
                          Dados titular
                          <v-spacer></v-spacer>
                          <v-icon
                            v-if="valid"
                            color="green"
                            size="25"
                            class="mr-2"
                            >mdi-check-circle</v-icon
                          >
                          <v-icon
                            v-else-if="failed"
                            color="red"
                            size="25"
                            class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                        </div>
                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <base-text-field
                          :data-test-id="'nome-completo'"
                          mode="aggressive"
                          rules="required|special_characters"
                          id="nome"
                          name="Nome completo"
                          v-model="form.nome"
                          label="Nome completo *"
                          :disabled="desabilitaInput"
                          clearable
                          outlined
                        ></base-text-field>

                        <base-text-field
                          :rules="validacaoCPFTitular(form.cpf)"
                          :data-test-id="'valida-cpf'"
                          id="cpf"
                          inputmode="numeric"
                          name="CPF"
                          v-model="form.cpf"
                          label="CPF *"
                          v-mask="'###.###.###-##'"
                          :disabled="desabilitaInput"
                          clearable
                          outlined
                          ref="cpf"
                          @blur="setObrigatoriedadePis()"
                        ></base-text-field>

                        <base-text-field
                          type="text"
                          :data-test-id="'valida-dob'"
                          inputmode="numeric"
                          rules="required"
                          id="nascimento"
                          name="Data de nascimento"
                          v-model="form.dataNascimento"
                          label="Data de nascimento *"
                          v-mask="'##/##/####'"
                          :disabled="desabilitaInput"
                          @input="setIdade"
                          clearable
                          outlined
                        ></base-text-field>

                        <base-text-field
                          mode="aggressive"
                          :data-test-id="'nome-mae'"
                          rules="required|special_characters"
                          id="nomeMae"
                          name="Nome da mãe"
                          v-model="form.nomeMae"
                          label="Nome da mãe *"
                          clearable
                          outlined
                        ></base-text-field>

                        <base-text-field
                          type="email"
                          :data-test-id="'valida-email'"
                          mode="eager"
                          :rules="idade >= 18 ? 'required|email' : 'email'"
                          v-show="idade >= 18"
                          id="email"
                          name="E-mail"
                          v-model="form.email"
                          :label="`E-mail ${idade >= 18 ? '*' : ''}`"
                          :disabled="desabilitaInput"
                          clearable
                          outlined
                        ></base-text-field>

                        <async-tipo-sexo
                          :key="'c_tipo-sexo-' + keyTipoSexo"
                          v-slot="{ loading: loadingTipoSexo, data }"
                        >
                          <base-wrapper-dialog-options
                            v-if="!loadingTipoSexo"
                            :key="'d_tipo-sexo-' + keyTipoSexo"
                            headerTitle="Sexo"
                            :options="data"
                            @select="$set(form, 'sexo', $event.id)"
                            value-key="id"
                            label-key="nome"
                            ref="dialogSexo"
                            :optionSelected="form.sexo"
                            :loading="loadingTipoSexo"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                readonly
                                rules="required"
                                placeholder="Clique aqui para selecionar"
                                id="sexo"
                                name="Sexo"
                                :data-test-id="'selecionar-sexo'"
                                v-on="on"
                                :value="form.sexo"
                                label="Sexo *"
                                outlined
                                append-icon="mdi-chevron-right"
                                :loading="loadingTipoSexo"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                        </async-tipo-sexo>

                        <async-estado-civil
                          :key="'c_estado-civil-' + keyEstadoCivil"
                          v-slot="{ loading: loadingEstadoCivil, data }"
                        >
                          <base-wrapper-dialog-options
                            v-if="!loadingEstadoCivil"
                            :key="'d_estado-civil-' + keyEstadoCivil"
                            headerTitle="Estado civil"
                            :options="data"
                            @select="$set(form, 'estadoCivil', $event.id)"
                            value-key="id"
                            label-key="nome"
                            ref="dialogEstadoCivil"
                            :optionSelected="form.estadoCivil"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                :loading="loadingEstadoCivil"
                                rules="required"
                                readonly
                                id="estadoCivil"
                                placeholder="Clique aqui para selecionar"
                                :data-test-id="'selecionar-estado-civil'"
                                name="Estado civil"
                                v-on="on"
                                :value="form.estadoCivil"
                                label="Estado civil *"
                                outlined
                                append-icon="mdi-chevron-right"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                        </async-estado-civil>
                        <base-text-field
                          type="tel"
                          mode="eager"
                          :rules="
                            idade >= 18
                              ? 'required|cellphone|ddd'
                              : 'cellphone|ddd'
                          "
                          v-show="idade >= 18"
                          id="telefoneCelular"
                          name="Telefone celular"
                          :data-test-id="'informar-telefone'"
                          v-model="form.telefoneCelular"
                          :label="`Telefone Celular ${idade >= 18 ? '*' : ''}`"
                          v-mask="'## #####-####'"
                          :disabled="desabilitaInput"
                          clearable
                          outlined
                          hint="Mesmo número que haverá confirmação via sms para conclusão da proposta"
                          persistent-hint
                          class="mb-3"
                        >
                          <v-tooltip top right>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-information</v-icon>
                            </template>
                            <span class="caption" style="line-height: 1"
                              >Mesmo número que haverá confirmação via sms para
                              conclusão da proposta</span
                            >
                          </v-tooltip>
                        </base-text-field>

                        <div
                          v-for="(item, index) in telefonesOpcionais"
                          :key="index"
                        >
                          <async-tipos-telefone
                            :key="'c_tipo-telefone-' + keyTipoTelefone"
                            v-slot="{ loading: loadingTipoTelefone, data }"
                          >
                            <base-wrapper-dialog-options
                              :key="'dialog_tel_opcional-' + index"
                              :headerTitle="`Tipo telefone ${
                                telefonesOpcionais.length > 1 ? index + 1 : ''
                              }`"
                              :options="data"
                              @select="
                                $set(
                                  telefonesOpcionais[index],
                                  'tipoTelefoneOpcional',
                                  $event.id
                                )
                              "
                              value-key="id"
                              label-key="nome"
                              :ref="`dialogTipoTelefoneOpcional_${index + 1}`"
                            >
                              <template v-slot:activador="{ on }">
                                <base-text-field
                                  readonly
                                  rules="required"
                                  v-on="on"
                                  :id="'text_tipo_opcional-' + index"
                                  :data-test-id="`'informar-telefone-opcional ${
                                    index + 1
                                  }'`"
                                  :name="`Informar telefone adicional (opcional) ${
                                    index + 1
                                  }`"
                                  :value="
                                    telefonesOpcionais[index]
                                      .tipoTelefoneOpcional || 'SEM TELEFONE'
                                  "
                                  :placeholder="`Clique aqui para selecionar ${
                                    index + 1
                                  } `"
                                  :label="`Informar telefone adicional (opcional) ${
                                    telefonesOpcionais.length > 1
                                      ? index + 1
                                      : ''
                                  }`"
                                  :loading="loadingTipoTelefone"
                                  outlined
                                  append-icon="mdi-chevron-right"
                                  :disabled="!telefonesOpcionais[index].hasEdit"
                                ></base-text-field>
                              </template>
                            </base-wrapper-dialog-options>
                            <base-text-field
                              v-if="
                                telefonesOpcionais[index].tipoTelefoneOpcional
                              "
                              type="tel"
                              :rules="
                                telefonesOpcionais[index]
                                  .tipoTelefoneOpcional === 'RESIDENCIAL'
                                  ? 'required|ddd|residencePhone'
                                  : 'required|ddd|phone'
                              "
                              :id="'text_tel_opcional-' + index"
                              :name="`Telefone opcional ${index + 1}`"
                              v-mask="['## #####-####', '## ####-####']"
                              v-model="
                                telefonesOpcionais[index].telefoneOpcional
                              "
                              :label="`Telefone opcional ${
                                telefonesOpcionais.length > 1 ? index + 1 : ''
                              } *`"
                              :disabled="!telefonesOpcionais[index].hasEdit"
                              clearable
                              outlined
                            >
                            </base-text-field>
                          </async-tipos-telefone>
                        </div>

                        <base-text-field
                          :rules="
                            rgObrigatorio
                              ? 'required'
                              : '' || idade >= 18
                              ? 'required|identidade'
                              : ''
                          "
                          id="identidade"
                          name="Identidade"
                          :data-test-id="'identidade'"
                          v-model="form.rg"
                          :label="`Identidade ${idade >= 18 ? '*' : ''}`"
                          maxlength="20"
                          clearable
                          outlined
                        ></base-text-field>
                        <div
                          v-if="
                            emissorObrigatorio &&
                            form.administradora !== 'plural'
                          "
                        >
                          <base-text-field
                            :rules="emissorObrigatorio ? 'required' : ''"
                            id="rgOrgaoEmissor"
                            name="Orgão Emissor"
                            :data-test-id="'orgao-emissor'"
                            :label="`Orgão Emissor ${
                              emissorObrigatorio ? '*' : ''
                            }`"
                            v-model="form.rgOrgaoEmissor"
                            v-mask="'AAAAAAAAA'"
                            clearable
                            outlined
                          ></base-text-field>
                        </div>
                        <div
                          v-if="
                            emissorObrigatorio &&
                            form.administradora === 'plural'
                          "
                        >
                          <base-wrapper-dialog-options
                            headerTitle="Orgão Emissor"
                            :options="listOrgaoEmissor"
                            @select="$set(form, 'rgOrgaoEmissor', $event.id)"
                            value-key="id"
                            label-key="nome"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                :rules="emissorObrigatorio ? 'required' : ''"
                                placeholder="Clique aqui para selecionar"
                                id="rgOrgaoEmissor"
                                name="Orgão Emissor"
                                v-on="on"
                                :value="form.rgOrgaoEmissor"
                                :label="`Orgão Emissor ${
                                  emissorObrigatorio ? '*' : ''
                                }`"
                                outlined
                                append-icon="mdi-chevron-right"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                        </div>
                        <div v-if="ufObrigatorio">
                          <base-wrapper-dialog-options
                            headerTitle="UF Orgão Emissor"
                            :options="listUf"
                            @select="$set(form, 'rgOrgaoEmissorUf', $event.id)"
                            value-key="id"
                            label-key="nome"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                :rules="ufObrigatorio ? 'required' : ''"
                                placeholder="Clique aqui para selecionar"
                                id="rgOrgaoEmissorUf"
                                name="UF Orgão Emissor"
                                v-on="on"
                                :value="form.rgOrgaoEmissorUf"
                                :label="`UF Orgão Emissor ${
                                  ufObrigatorio ? '*' : ''
                                }`"
                                outlined
                                append-icon="mdi-chevron-right"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                        </div>
                        <div v-if="dataExpedicaoObrigatorio">
                          <base-text-field
                            :rules="dataExpedicaoObrigatorio ? 'required' : ''"
                            id="rgDataExpedicao"
                            name="Data de Expedição"
                            :label="`Data de Expedição ${
                              dataExpedicaoObrigatorio ? '*' : ''
                            }`"
                            v-model="form.rgDataExpedicao"
                            v-mask="'##/##/####'"
                            clearable
                            outlined
                          ></base-text-field>
                        </div>
                        <div v-if="cnsObrigatorio">
                          <base-text-field
                            :rules="cnsObrigatorio ? 'required|cns' : ''"
                            id="numeroCns"
                            name="Cartão Nacional Saúde"
                            :data-test-id="'numero-cns'"
                            :label="`Cartão Nacional Saúde ${
                              cnsObrigatorio ? '*' : ''
                            }`"
                            v-model="form.cns"
                            v-mask="'###.####.####.####'"
                            inputmode="numeric"
                            clearable
                            outlined
                          ></base-text-field>
                        </div>
                        <div v-if="dnvObrigatorio">
                          <base-text-field
                            :rules="dnvObrigatorio ? 'required' : ''"
                            id="numeroDnv"
                            name="Declaração Nascido Vivo"
                            :data-test-id="'numero-dnv'"
                            :label="`Declaração Nascido Vivo ${
                              dnvObrigatorio ? '*' : ''
                            }`"
                            v-model="form.declaracaoNascidoVivo"
                            maxlength="12"
                            v-mask="'##-########-#'"
                            inputmode="numeric"
                            clearable
                            outlined
                          ></base-text-field>
                        </div>
                        <base-text-field
                          :rules="pisObrigatorio ? 'required' : ''"
                          id="pisPasep"
                          name="PIS/PASEP"
                          :data-test-id="'pis-pasep'"
                          v-model="form.pisPasep"
                          :label="pisObrigatorio ? 'PIS/PASEP*' : 'PIS/PASEP'"
                          v-mask="'###.#####.##-#'"
                          inputmode="numeric"
                          clearable
                          outlined
                        ></base-text-field>
                        <div
                          class="input-dt-nascimento"
                          v-if="dadosTitularState.entidade === 'ANAJUSTRA'"
                        >
                          <base-text-field
                            :autoBlur="10"
                            :rules="
                              dadosTitularState.entidade === 'ANAJUSTRA'
                                ? 'required'
                                : ''
                            "
                            inputmode="numeric"
                            type="text"
                            :data-test-id="'preencher-matriculaFuncional'"
                            id="matriculaFuncional"
                            name="matriculaFuncional"
                            label="Matrícula Funcional"
                            clearable
                            outlined
                            v-model="form.matriculaFuncional"
                          ></base-text-field>
                        </div>
                        <base-wrapper-dialog-options
                          v-if="form.administradora === 'alianca'"
                          headerTitle="Empossado/Pensionista"
                          :options="[
                            { id: 'Sim', nome: 'Sim', value: true },
                            { id: 'Não', nome: 'Não', value: false },
                          ]"
                          @select="
                            $set(form, 'empossadoPensionista', $event.id)
                          "
                          value-key="id"
                          label-key="nome"
                          ref="dialogEmpossadoPensionista"
                        >
                          <template v-slot:activador="{ on }">
                            <base-text-field
                              readonly
                              v-on="on"
                              rules="required"
                              :data-test-id="'reducao-carencia'"
                              id="empossadoPensionista"
                              name="EmpossadoPensionista"
                              :value="form.empossadoPensionista"
                              placeholder="Clique aqui para selecionar"
                              label="Recém Empossado/Pensionista"
                              outlined
                              append-icon="mdi-chevron-right"
                            ></base-text-field>
                          </template>
                        </base-wrapper-dialog-options>
                        <v-row
                          v-if="
                            propostaState.corretor.tipoCorretor == 'Externo' &&
                            propostaState.plano.operadora == 'Unimed BH'
                          "
                        >
                          <v-col
                            cols="12"
                            :md="
                              form.sumula && form.sumula == 'Sim' ? '6' : '12'
                            "
                            class="pb-0"
                          >
                            <base-wrapper-dialog-options
                              headerTitle="Súmula 21"
                              :options="[
                                { id: 'Sim', nome: 'Sim', value: true },
                                { id: 'Não', nome: 'Não', value: false },
                              ]"
                              @select="$set(form, 'sumula', $event.id)"
                              value-key="id"
                              label-key="nome"
                              ref="dialogEmpossadoPensionista"
                            >
                              <template v-slot:activador="{ on }">
                                <base-text-field
                                  readonly
                                  v-on="on"
                                  rules="required"
                                  :data-test-id="'reducao-carencia'"
                                  id="sumula"
                                  name="Sumula"
                                  :value="form.sumula"
                                  placeholder="Clique aqui para selecionar"
                                  label="Súmula 21"
                                  outlined
                                  append-icon="mdi-chevron-right"
                                ></base-text-field>
                              </template>
                            </base-wrapper-dialog-options>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            class="pb-0"
                            v-if="form.sumula && form.sumula == 'Sim'"
                          >
                            <h2
                              class="primary--text mb-2 subtitle-1 font-weight-bold"
                            >
                              Tempo plano anterior
                            </h2>
                            <v-divider></v-divider>
                            <validation-provider
                              name="Tempo do Plano Anterior"
                              vid="sumulaPlanoAnterior"
                              v-slot="{ errors }"
                              :rules="form.sumula ? 'required' : ''"
                            >
                              <v-radio-group
                                v-model="form.sumulaPlanoAnterior"
                                :mandatory="false"
                                :error-messages="errors"
                              >
                                <v-radio
                                  :data-test-id="'maior-24'"
                                  class="mb-4"
                                  label="MAIOR QUE 24 MESES"
                                  :value="1"
                                ></v-radio>
                                <v-divider></v-divider>
                                <v-radio
                                  :data-test-id="'menor-24'"
                                  class="mb-4 mt-4"
                                  label="MENOR QUE 24 MESES"
                                  :value="0"
                                ></v-radio>
                              </v-radio-group>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>

                  <!-- Endereço residencial -->
                  <validation-observer
                    ref="enderecoResidencial"
                    slim
                    v-slot="{ valid, failed }"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :data-test-id="'expandir-endereco-residencial'"
                        class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3"
                        style="min-height: auto; justify-content: space-between"
                      >
                        <div class="d-flex align-center">
                          Endereço residencial
                          <v-spacer></v-spacer>
                          <v-icon
                            v-if="valid"
                            color="green"
                            size="25"
                            class="mr-2"
                            >mdi-check-circle</v-icon
                          >
                          <v-icon
                            v-else-if="failed"
                            color="red"
                            size="25"
                            class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                        </div>

                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <async-cep-residencial
                          :cep="enderecoResidencial.cep"
                          :loadingMounted="loadingMountedCepResidencial"
                          v-slot="{ loading: loadingRequestCep }"
                          @success="successCep('residencial', $event)"
                          @error="loadingMountedCepResidencial = false"
                        >
                          <base-text-field
                            rules="required|min:9"
                            inputmode="numeric"
                            id="cep"
                            name="CEP"
                            :data-test-id="'cep-residencial'"
                            disabled
                            v-model="enderecoResidencial.cep"
                            label="CEP *"
                            :loading="loadingRequestCep"
                            clearable
                            outlined
                            v-mask="'#####-###'"
                          ></base-text-field>
                        </async-cep-residencial>
                        <base-text-field
                          rules="required"
                          :data-test-id="'uf'"
                          id="uf"
                          name="UF"
                          :disabled="habilitaUf"
                          v-mask="'AA'"
                          :value="enderecoResidencial.estado"
                          label="UF *"
                          outlined
                        ></base-text-field>

                        <base-text-field
                          :disabled="habilitaCidade"
                          rules="required"
                          :data-test-id="'municipio'"
                          id="municipio"
                          name="Município"
                          :value="enderecoResidencial.cidade"
                          label="Município *"
                          outlined
                        ></base-text-field>

                        <base-text-field
                          rules="required"
                          :data-test-id="'bairro'"
                          id="bairro"
                          name="Bairro"
                          v-model="enderecoResidencial.bairro"
                          :disabled="habilitaBairro"
                          label="Bairro *"
                          clearable
                          outlined
                        ></base-text-field>

                        <base-text-field
                          rules="required"
                          id="logradouro"
                          :data-test-id="'logradouro'"
                          name="Logradouro"
                          v-model="enderecoResidencial.logradouro"
                          :disabled="habilitaLogradouro"
                          label="Logradouro *"
                          clearable
                          outlined
                        ></base-text-field>

                        <base-text-field
                          rules="required"
                          v-mask="['############']"
                          :data-test-id="'numero-casa'"
                          id="numero"
                          name="Número"
                          v-model="enderecoResidencial.numero"
                          label="Número *"
                          clearable
                          outlined
                        ></base-text-field>

                        <base-text-field
                          rules=""
                          id="complemento"
                          :data-test-id="'complemento'"
                          name="Complemento"
                          v-model="enderecoResidencial.complemento"
                          label="Complemento"
                          clearable
                          outlined
                          maxlength="30"
                        ></base-text-field>

                        <validation-provider
                          tag="div"
                          class="d-flex align-center mb-5"
                        >
                          <span
                            class="primary--text subtitle-2 font-weight-regular pr-4 mt-1"
                            >Possui endereço comercial?</span
                          >
                          <v-spacer></v-spacer>
                          <v-switch
                            class="ma-0"
                            hide-details
                            v-model="possuiEnderecoComercial"
                            :disabled="
                              !possuiEnderecoComercialState && desabilitaInput
                            "
                          ></v-switch>
                        </validation-provider>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>
                  <!-- Endereço comercial -->
                  <validation-observer
                    ref="enderecoComercial"
                    slim
                    v-slot="{ valid, failed }"
                    v-if="possuiEnderecoComercial"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3"
                        style="min-height: auto; justify-content: space-between"
                      >
                        <div class="d-flex align-center">
                          Endereço comercial
                          <v-spacer></v-spacer>
                          <v-icon
                            v-if="valid"
                            color="green"
                            size="25"
                            class="mr-2"
                            >mdi-check-circle</v-icon
                          >
                          <v-icon
                            v-else-if="failed"
                            color="red"
                            size="25"
                            class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                        </div>

                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <async-cep-comercial
                          :cep="enderecoComercial.cep"
                          :loadingMounted="loadingMountedCepComercial"
                          v-slot="{ loading: loadingRequestCep }"
                          @success="successCep('comercial', $event)"
                          @error="loadingMountedCepComercial = false"
                        >
                          <base-text-field
                            rules="required|min:9"
                            inputmode="numeric"
                            id="cep"
                            name="CEP"
                            :data-test-id="'cep-comercial'"
                            v-model="enderecoComercial.cep"
                            label="CEP *"
                            clearable
                            :loading="loadingRequestCep"
                            outlined
                            v-mask="'#####-###'"
                          ></base-text-field>

                          <base-text-field
                            rules="required"
                            id="uf"
                            name="UF"
                            :data-test-id="'uf-comercial'"
                            v-mask="'AA'"
                            :value="enderecoComercial.estado"
                            :disabled="enderecoComercial.estado ? true : false"
                            label="UF *"
                            outlined
                          ></base-text-field>

                          <base-text-field
                            :disabled="enderecoComercial.cidade ? true : false"
                            rules="required"
                            id="municipio"
                            :data-test-id="'municipio-comercial'"
                            name="Município"
                            :value="enderecoComercial.cidade"
                            label="Município *"
                            outlined
                          ></base-text-field>

                          <base-text-field
                            rules="required"
                            :data-test-id="'bairro-comercial'"
                            id="bairro"
                            name="Bairro"
                            v-model="enderecoComercial.bairro"
                            label="Bairro *"
                            clearable
                            outlined
                          ></base-text-field>

                          <base-text-field
                            rules="required"
                            id="logradouro"
                            :data-test-id="'logradouro-comercial'"
                            name="Logradouro"
                            v-model="enderecoComercial.logradouro"
                            label="Logradouro *"
                            clearable
                            outlined
                          ></base-text-field>

                          <base-text-field
                            rules="required"
                            v-mask="['############']"
                            id="numero"
                            :data-test-id="'numero-comercial'"
                            name="Número"
                            v-model="enderecoComercial.numero"
                            label="Número *"
                            clearable
                            outlined
                          ></base-text-field>

                          <base-text-field
                            rules=""
                            id="complemento"
                            :data-test-id="'complemento-comercial'"
                            name="Complemento"
                            v-model="enderecoComercial.complemento"
                            label="Complemento"
                            clearable
                            outlined
                            maxlength="30"
                          ></base-text-field>
                        </async-cep-comercial>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>

                  <!-- Endereço correspondência -->
                  <validation-observer
                    ref="enderecoCorrespondencia"
                    slim
                    v-slot="{ valid, failed }"
                    v-if="
                      enderecoComercial &&
                      enderecoComercial.cep &&
                      possuiEnderecoComercial
                    "
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3"
                        style="min-height: auto; justify-content: space-between"
                      >
                        <div class="d-flex align-center">
                          Endereço correspondência
                          <v-spacer></v-spacer>
                          <v-icon
                            v-if="valid"
                            color="green"
                            size="25"
                            class="mr-2"
                            >mdi-check-circle</v-icon
                          >
                          <v-icon
                            v-else-if="failed"
                            color="red"
                            size="25"
                            class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                        </div>

                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <validation-provider
                          slim
                          v-slot="{ errors }"
                          name="Endereço correspondência"
                          vid="enderecoCorrespondencia"
                          rules="required"
                        >
                          <v-radio-group
                            @change="setEnderecoCobranca($event)"
                            :value="enderecoCobranca"
                            :mandatory="false"
                            :error-messages="errors"
                            class="ma-0 pa-0"
                          >
                            <v-radio
                              class="mb-4"
                              label="Residencial"
                              value="residencial"
                            ></v-radio>
                            <v-radio
                              label="Comercial"
                              value="comercial"
                            ></v-radio>
                          </v-radio-group>
                        </validation-provider>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>

                  <!-- Redução de carência -->
                  <validation-observer
                    ref="reducaoCarencia"
                    slim
                    v-slot="{ valid, failed }"
                    v-if="exibirReducaoCarencia"
                  >
                    <v-expansion-panel @click="setCarencia">
                      <v-expansion-panel-header
                        :data-test-id="'expandir-reducao-carencia'"
                        class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3"
                        style="min-height: auto; justify-content: space-between"
                      >
                        <div class="d-flex align-center">
                          Redução de carência
                          <v-spacer></v-spacer>
                          <v-icon
                            v-if="valid"
                            color="green"
                            size="25"
                            class="mr-2"
                            >mdi-check-circle</v-icon
                          >
                          <v-icon
                            v-else-if="failed"
                            color="red"
                            size="25"
                            class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                        </div>

                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <base-wrapper-dialog-options
                          headerTitle="Redução de carência"
                          :options="[
                            { id: 'Sim', nome: 'Sim', value: true },
                            { id: 'Não', nome: 'Não', value: false },
                          ]"
                          @select="$set(form, 'reducaoCarencia', $event.id)"
                          value-key="value"
                          label-key="nome"
                          ref="dialogReducaoCarencia"
                          :optionSelected="form.reducaoCarencia"
                        >
                          <template v-slot:activador="{ on }">
                            <base-text-field
                              readonly
                              v-on="on"
                              rules="required"
                              :data-test-id="'reducao-carencia'"
                              id="reducaoCarencia"
                              name="ReducaoCarencia"
                              :value="form.reducaoCarencia"
                              placeholder="Clique aqui para selecionar"
                              label="Vai anexar documento que comprove redução de carência? *"
                              outlined
                              append-icon="mdi-chevron-right"
                            ></base-text-field>
                          </template>
                        </base-wrapper-dialog-options>
                        <div
                          v-if="
                            form.plano.operadora.toLowerCase() ===
                            'unimed volta redonda'
                          "
                        >
                          <base-text-field
                            inputmode="text"
                            :rules="
                              form.reducaoCarencia === 'Sim' ? 'required' : ''
                            "
                            id="operadoraAnterior"
                            name="Operadora Anterior"
                            v-model="form.operadoraAnterior"
                            :label="`Operadora Anterior ${
                              form.reducaoCarencia === 'Sim' ? '*' : ''
                            }`"
                            clearable
                            outlined
                          ></base-text-field>
                          <base-text-field
                            inputmode="numeric"
                            :rules="
                              form.reducaoCarencia === 'Sim' ? 'required' : ''
                            "
                            id="tempoPlanoEmMesesAnterior"
                            name="Tempo em meses do plano Anterior"
                            v-model="form.tempoPlanoEmMesesAnterior"
                            :label="`Tempo em meses do plano Anterior ${
                              form.reducaoCarencia === 'Sim' ? '*' : ''
                            }`"
                            clearable
                            outlined
                            v-mask="'###'"
                          ></base-text-field>
                          <base-wrapper-dialog-options
                            headerTitle="Redução de carência"
                            :options="[
                              {
                                id: 'Coletiva',
                                nome: 'Coletiva',
                                value: 'coletiva',
                              },
                              {
                                id: 'Individual',
                                nome: 'Individual',
                                value: 'individual',
                              },
                            ]"
                            @select="
                              $set(form, 'acomodacaoAnterior', $event.id)
                            "
                            value-key="value"
                            label-key="nome"
                            ref="dialogAcomodacao"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                readonly
                                clearable
                                v-on="on"
                                :rules="
                                  form.reducaoCarencia === 'Sim'
                                    ? 'required'
                                    : ''
                                "
                                id="acomodacaoAnterior"
                                name="acomodacaoAnterior"
                                :value="form.acomodacaoAnterior"
                                v-model="form.acomodacaoAnterior"
                                placeholder="Clique aqui para selecionar"
                                :label="`Selecione a acomodação do plano anterior ${
                                  form.reducaoCarencia === 'Sim' ? '*' : ''
                                }`"
                                outlined
                                append-icon="mdi-chevron-right"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                          <base-wrapper-dialog-options
                            headerTitle="Item de redução de Carência"
                            :options="opcoesCarencia"
                            @select="selectCarencia"
                            value-key="value"
                            label-key="nome"
                            ref="dialogAcomodacao"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                readonly
                                v-on="on"
                                :rules="
                                  form.reducaoCarencia === 'Sim'
                                    ? 'required'
                                    : ''
                                "
                                id="itemCarenciaAnterior"
                                name="itemCarenciaAnterior"
                                :value="form.itemCarenciaAnterior"
                                v-model="textCarencia"
                                placeholder="Clique aqui para selecionar"
                                :label="`Selecione o item de redução de carência do plano anterior ${
                                  form.reducaoCarencia === 'Sim' ? '*' : ''
                                }`"
                                outlined
                                append-icon="mdi-chevron-right"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                        </div>
                        <div
                          v-if="
                            form.plano.operadora.toLowerCase() ===
                              'unimed bh' && form.reducaoCarencia === 'Sim'
                          "
                        >
                          <base-wrapper-dialog-options
                            headerTitle="Informe se a operadora anterior era Unimed BH"
                            :options="[
                              { id: 'Sim', nome: 'Sim', value: 'Sim' },
                              { id: 'Não', nome: 'Não', value: 'Não' },
                            ]"
                            @select="
                              $set(
                                form,
                                'confirmarOperadoraAnterior',
                                $event.id
                              )
                            "
                            value-key="value"
                            label-key="nome"
                            ref="dialogAcomodacao"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                readonly
                                clearable
                                v-on="on"
                                :rules="
                                  form.reducaoCarencia === 'Sim'
                                    ? 'required'
                                    : ''
                                "
                                id="confirmarOperadoraAnterior"
                                name="Informe se a operadora anterior era Unimed BH"
                                :value="form.confirmarOperadoraAnterior"
                                v-model="form.confirmarOperadoraAnterior"
                                placeholder="Clique aqui para selecionar"
                                :label="`Informe se a operadora anterior era Unimed BH ${
                                  form.reducaoCarencia === 'Sim' ? '*' : ''
                                }`"
                                outlined
                                append-icon="mdi-chevron-right"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                          <div v-if="form.confirmarOperadoraAnterior === 'Sim'">
                            <base-text-field
                              inputmode="numeric"
                              :rules="
                                form.confirmarOperadoraAnterior === 'Sim'
                                  ? 'required'
                                  : ''
                              "
                              id="campoCarteirinha"
                              name="Número Carteirinha"
                              v-model="form.campoCarteirinha"
                              v-mask="'###################'"
                              :label="`Informe o número da carteirinha do plano anterior  ${
                                form.confirmarOperadoraAnterior === 'Sim'
                                  ? '*'
                                  : ''
                              }`"
                              clearable
                              outlined
                            ></base-text-field>
                          </div>
                        </div>
                        <div
                          v-if="
                            propostaState.plano.operadora.toLowerCase() ===
                              'unimed-rio' && form.reducaoCarencia !== ''
                          "
                        >
                          <base-wrapper-dialog-options
                            headerTitle="Possuí plano anterior ? *"
                            :options="[
                              { id: 'Sim', nome: 'Sim', value: 'Sim' },
                              { id: 'Não', nome: 'Não', value: 'Não' },
                            ]"
                            @select="
                              $set(
                                form,
                                'possuiPlanoAnteriorReducaoCarencia',
                                $event.id
                              )
                            "
                            value-key="value"
                            label-key="nome"
                            ref="dialogAcomodacao"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                readonly
                                clearable
                                v-on="on"
                                rules="required"
                                id="possuiPlanoAnteriorReducaoCarencia"
                                name="Possuí plano anterior ?"
                                :value="form.possuiPlanoAnteriorReducaoCarencia"
                                v-model="
                                  form.possuiPlanoAnteriorReducaoCarencia
                                "
                                placeholder="Clique aqui para selecionar"
                                label="Possuí plano anterior ? *"
                                outlined
                                append-icon="mdi-chevron-right"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                          <base-text-field
                            inputmode="numeric"
                            :rules="
                              form.possuiPlanoAnteriorReducaoCarencia === 'Sim'
                                ? 'required'
                                : ''
                            "
                            v-mask="['###']"
                            id="tempoPlanoAnteriorReducaoCarencia"
                            name="Qual o tempo do plano anterior? Informe os meses"
                            v-model="form.tempoPlanoAnteriorReducaoCarencia"
                            :label="`Qual o tempo do plano anterior? Informe os meses ${
                              form.possuiPlanoAnteriorReducaoCarencia === 'Sim'
                                ? '*'
                                : ''
                            }`"
                            clearable
                            outlined
                          ></base-text-field>
                          <base-text-field
                            inputmode="text"
                            :rules="
                              form.possuiPlanoAnteriorReducaoCarencia === 'Sim'
                                ? 'required'
                                : ''
                            "
                            id="operadoraPlanoAnteriorReducaoCarencia"
                            name="Qual Operadora do plano anterior?"
                            v-model="form.operadoraPlanoAnteriorReducaoCarencia"
                            :label="`Qual Operadora do plano anterior? ${
                              form.possuiPlanoAnteriorReducaoCarencia === 'Sim'
                                ? '*'
                                : ''
                            }`"
                            clearable
                            outlined
                          ></base-text-field>
                          <base-text-field
                            inputmode="text"
                            :rules="
                              form.possuiPlanoAnteriorReducaoCarencia === 'Sim'
                                ? 'required'
                                : ''
                            "
                            id="registroAnsPlanoAnteriorReducaoCarencia"
                            name="Qual Registro ANS do plano anterior?"
                            v-model="
                              form.registroAnsPlanoAnteriorReducaoCarencia
                            "
                            :label="`Qual Registro ANS do plano anterior? ${
                              form.possuiPlanoAnteriorReducaoCarencia === 'Sim'
                                ? '*'
                                : ''
                            }`"
                            clearable
                            outlined
                          ></base-text-field>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>

                  <!-- DADOS COMPLEMENTARES OPERADORA -->
                  <validation-observer
                    ref="dadosComplementaresOperadora"
                    slim
                    v-slot="{ valid, failed }"
                    v-if="exibirDadosComplementares"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3"
                        style="min-height: auto; justify-content: space-between"
                      >
                        <div class="d-flex align-center">
                          Dados complementares da operadora
                          <v-spacer></v-spacer>
                          <v-icon
                            v-if="valid"
                            color="green"
                            size="25"
                            class="mr-2"
                            >mdi-check-circle</v-icon
                          >
                          <v-icon
                            v-else-if="failed"
                            color="red"
                            size="25"
                            class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                        </div>

                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <base-text-field
                          :data-test-id="'dadosComplementaresOperadora-matricula'"
                          mode="aggressive"
                          rules=""
                          id="matricula"
                          name="Matrícula funcional"
                          v-model="dadosComplementaresOperadora.matricula"
                          label="Matrícula funcional"
                          :disabled="desabilitaInput"
                          clearable
                          outlined
                        ></base-text-field>
                        <base-wrapper-dialog-options
                          headerTitle="Patrocinadora"
                          :options="patrocinadoraCemigList"
                          @select="
                            $set(
                              dadosComplementaresOperadora,
                              'patrocinadora',
                              $event.id
                            )
                          "
                          value-key="id"
                          label-key="nome"
                          ref="dialogPatrocinadora"
                          :optionSelected="
                            dadosComplementaresOperadora.patrocinadora
                          "
                        >
                          <template v-slot:activador="{ on }">
                            <base-text-field
                              rules="required"
                              readonly
                              id="patrocinadora"
                              placeholder="Clique aqui para selecionar"
                              :data-test-id="'dadosComplementaresOperadora-patrocinadora'"
                              name="Patrocinadora"
                              v-on="on"
                              :value="
                                dadosComplementaresOperadora.patrocinadora
                              "
                              label="Patrocinadora *"
                              :disabled="desabilitaInput"
                              outlined
                              append-icon="mdi-chevron-right"
                            ></base-text-field>
                          </template>
                        </base-wrapper-dialog-options>
                        <base-text-field
                          :data-test-id="'dadosComplementaresOperadora-categoria'"
                          mode="aggressive"
                          rules=""
                          id="categoria"
                          name="Categoria"
                          v-model="dadosComplementaresOperadora.categoria"
                          label="Categoria"
                          :disabled="desabilitaInput"
                          clearable
                          outlined
                        ></base-text-field>
                        <base-text-field
                          :data-test-id="'dadosComplementaresOperadora-nome-pai'"
                          mode="aggressive"
                          rules="required|special_characters"
                          id="nomePai"
                          name="Nome completo do pai"
                          v-model="dadosComplementaresOperadora.nomePai"
                          label="Nome completo do pai *"
                          :disabled="desabilitaInput"
                          clearable
                          outlined
                        ></base-text-field>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>
                </v-expansion-panels>
                <v-btn
                  :data-test-id="'salvar-continuar-4'"
                  :loading="loadingSalvar"
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text"
                  >Salvar e continuar</v-btn
                >
              </validation-observer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <modal-confirmation
        :show="showModalDivergenciaUF"
        name="modalDivergenciaEstado"
        :loading="false"
        title="Divergência de estado nos endereços"
        text="Apenas é permitido a utilização de endereço comercial na mesma UF do endereço residencial."
        buttonConfirm="Ok"
        buttonCancel=""
        :width="500"
        @confirm="handleOkButtonModalDivergenciaUF"
      />
    </v-container>
  </div>
</template>

<script>
import AsyncCepResidencial from "@/components/AsyncCepResidencial.vue";
import AsyncCepComercial from "@/components/AsyncCepComercial.vue";
import AsyncTipoSexo from "@/components/AsyncTipoSexo.vue";
import AsyncEstadoCivil from "@/components/AsyncEstadoCivil.vue";
import AsyncTiposTelefone from "@/components/AsyncTiposTelefone.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import ModalConfirmation from "@/components/ModalConfirmation.vue";
import ufList from "@/utils/constants/ufList";
import orgaoEmissorList from "@/utils/constants/orgaoEmissorList";
import patrocinadoraCemigList from "@/utils/constants/patrocinadoraCemigList";
import propostaService from "@/services/propostaService";
import carenciasService from "@/services/carenciasService";
import regrasService from "@/services/regrasService";
import { mapState, mapActions } from "vuex";
import filters from "@/filters";
import validaService from "@/services/validaService";

export default {
  name: "DadosTitular",
  components: {
    AsyncCepResidencial,
    AsyncCepComercial,
    AsyncTipoSexo,
    AsyncEstadoCivil,
    AsyncTiposTelefone,
    BaseTextField,
    BaseWrapperDialogOptions,
    ModalConfirmation,
    // BaseWrapperDialogList,
  },
  data() {
    return {
      showModalDivergenciaUF: false,
      obrigatorio: true,
      loadingMountedCepResidencial: false,
      loadingMountedCepComercial: false,
      idade: 0,
      loadingSalvar: false,
      loading: false,
      keyTipoSexo: 0,
      keyEstadoCivil: 0,
      keyTipoTelefone: 0,
      listUf: ufList,
      listOrgaoEmissor: orgaoEmissorList,
      selectedCarencia: {},
      textCarencia: "",
      form: {
        reducaoCarencia: "",
        matriculaFuncional: "",
      },
      enderecoResidencial: {},
      opcoesCarencia: [],
      enderecoComercial: {},
      enderecoCobranca: "",
      possuiEnderecoComercial: false,
      removeEnderecoComercial: false,
      dadosProfissionais: {},
      habilitaUf: false,
      habilitaCidade: false,
      habilitaBairro: false,
      habilitaLogradouro: false,
      obrigatoriedades: [],
      rgObrigatorio: false,
      dnvObrigatorio: false,
      emissorObrigatorio: false,
      ufObrigatorio: false,
      dataExpedicaoObrigatorio: false,
      mounting: true,
      pisObrigatorio: false,
      cnsObrigatorio: false,
      telefonesOpcionais: [],
      hasAddTelefoneOpcional: false,
      anoNascimento: null,
      dadosComplementaresOperadora: {
        matricula: "",
        patrocinadora: "",
        categoria: "",
        nomePai: "",
      },
      patrocinadoraCemigList: patrocinadoraCemigList,
    };
  },
  computed: {
    ...mapState({
      dadosTitularState: (state) => state.cadastroProposta.titular,
      propostaState: (state) => state.cadastroProposta.proposta,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      dadosProfissionaisState: (state) =>
        state.cadastroProposta.titular.dadosProfissionais,
    }),
    desabilitaInput() {
      return (this.propostaState.status || "") !== "EM_DIGITACAO";
    },
    possuiEnderecoComercialState() {
      const data = this.$cleanObserver(this.dadosTitularState || {});
      const endereco = ((data && data.enderecos) || []).find(
        (e) => e.tipo.findIndex((i) => i === "ENDERECO_COMERCIAL") >= 0
      );
      return !!(endereco && endereco.cep);
    },
    exibirReducaoCarencia() {
      const operadoras =
        process.env.VUE_APP_ISENCAO_REDUCAO_CARENCIA_OPERADORAS.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .split(",");
      const operodaraDoState = this.propostaState.plano.operadora
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      let exibir = true;
      for (let index = 0, l = operadoras.length; index < l; index++) {
        const item = operadoras[index];
        if (item === operodaraDoState) {
          exibir = false;
          break;
        }
      }
      return exibir;
    },
    exibirDadosComplementares() {
      const operodaraDoState = this.propostaState.plano.operadora
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return operodaraDoState === "cemig saude";
    },
  },
  watch: {
    possuiEnderecoComercial: {
      handler(newValue) {
        if (this.form.enderecos) {
          if (!newValue) {
            const comercialIndex = this.form.enderecos.findIndex(
              (e) => e.tipo.findIndex((i) => i === "ENDERECO_COMERCIAL") >= 0
            );

            if (comercialIndex >= 0) {
              if (this.removeEnderecoComercial) {
                this.removeEndereco(comercialIndex);
              }
              this.form.enderecos.splice(comercialIndex, 1);
            }
          } else {
            const comercialIndex = this.form.enderecos.findIndex(
              (e) => e.tipo.findIndex((i) => i === "ENDERECO_COMERCIAL") >= 0
            );
            if (comercialIndex < 0) {
              const newEndComercial = {
                tipo: ["ENDERECO_COMERCIAL"],
              };
              this.form.enderecos.push(newEndComercial);
            }
          }
        }
      },
    },
    dadosTitularState: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
    propostaState: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
  },
  async created() {
    this.setObrigatoriedadePis();
    await this.setOpcoesCarencia();
    await this.buscaRegra();
    this.hasAddTelefoneOpcional =
      this.propostaState.status === "DEVOLVIDA" &&
      this.propostaState.devolucaoPorTentativaContato;
    this.pushTelefoneOpcional();
  },
  mounted() {
    if (this.enderecoResidencial.cep) this.loadingMountedCepResidencial = true;
    if (this.enderecoComercial.cep) this.loadingMountedCepComercial = true;
    this.setIdade();
  },
  methods: {
    ...mapActions({
      setDadosTitular: "cadastroProposta/setDadosTitular",
      setDadosProposta: "cadastroProposta/setDadosProposta",
      setDadosProfissionais: "cadastroProposta/setDadosProfissionais",
      setDadosSumula: "cadastroProposta/setDadosSumula",
      setBlnContinuar: "cadastroProposta/setBlnContinuar",
      setPlano: "cadastroProposta/setPlano",
    }),
    selectCarencia(options) {
      this.$set(this.form, "itemCarenciaAnterior", options.id);
      this.textCarencia = options.nome;
    },
    pushTelefoneOpcional() {
      const hasTelefoneOpcional = this.propostaState.titular.telefones.filter(
        ({ preferencial }) => !preferencial
      );
      if (hasTelefoneOpcional.length === 0 || this.hasAddTelefoneOpcional) {
        this.telefonesOpcionais.push({
          tipoTelefoneOpcional: undefined,
          telefoneOpcional: undefined,
          hasEdit: true,
        });
      }
    },
    setOptionalPhoneMask(type) {
      switch (type) {
        case "CELULAR":
          return "## #####-####";
        case "COMERCIAL":
          return "## ####-####";
        default:
          return "## ####-####";
      }
    },
    async buscaRegra() {
      const data = await regrasService.buscaRegraRg({
        operadora: this.propostaState.plano.operadora,
        idade: this.idade,
        anoNascimento: this.anoNascimento,
      });
      this.obrigatoriedades = data.data;
      if (this.obrigatoriedades && this.obrigatoriedades.length) {
        const rg = this.obrigatoriedades.find((e) => e.campo === "RG");
        this.rgObrigatorio = rg ? rg.obrigatorio : false;
        const orgaoEmissor = this.obrigatoriedades.find(
          (e) => e.campo === "Orgao Emissor"
        );
        this.emissorObrigatorio = orgaoEmissor
          ? orgaoEmissor.obrigatorio
          : false;
        const uf = this.obrigatoriedades.find((e) => e.campo === "UF");
        this.ufObrigatorio = uf ? uf.obrigatorio : false;
        const dataExpedicao = this.obrigatoriedades.find(
          (e) => e.campo === "Data Expedicao"
        );
        this.dataExpedicaoObrigatorio = dataExpedicao
          ? dataExpedicao.obrigatorio
          : false;
        const cns = this.obrigatoriedades.find((e) => e.campo === "CNS");
        this.cnsObrigatorio = cns ? cns.obrigatorio : false;
        const dnv = this.obrigatoriedades.find((e) => e.campo === "DNV");
        this.dnvObrigatorio = dnv ? dnv.obrigatorio : false;
      } else {
        this.rgObrigatorio = false;
        this.emissorObrigatorio = false;
        this.ufObrigatorio = false;
        this.dataExpedicaoObrigatorio = false;
        this.cnsObrigatorio = false;
        this.dnvObrigatorio = false;
      }
      return data;
    },
    async setOpcoesCarencia() {
      const data = await carenciasService.listaOpcoesCarencia({
        idProdutoFatura: this.propostaState.plano.idProdutoFatura,
        dataVigencia: this.propostaState.dataVigencia,
      });
      this.opcoesCarencia = data.data;
      this.opcoesCarencia = this.opcoesCarencia.map((e) => ({
        id: {
          codigoCarencia: e.codigoCarencia,
          descricaoCodigoCarencia: e.descricaoCodigoCarencia,
        },
        nome: `${e.codigoCarencia}  ${
          e.descricaoCodigoCarencia ? e.descricaoCodigoCarencia : ""
        }`,
        value: e.codigoCarencia,
      }));
    },
    async setIdade(e, valid) {
      if (
        this.form.dataNascimento &&
        filters.dateFormatValid(this.form.dataNascimento)
      ) {
        this.idade = filters.getAge(this.form.dataNascimento);
        const newLetForDate = this.form.dataNascimento;
        const dateArray = newLetForDate.split("/");
        const dateYear = dateArray[2];
        this.anoNascimento = Number(dateYear);
        await this.buscaRegra();
      }
    },
    setData() {
      if (!this.mounting) return;
      this.mounting = false;
      this.form = {
        ...this.form,
        ...this.$cleanObserver(this.dadosTitularState),
        ...this.$cleanObserver(this.propostaState),
      };

      if (this.form && this.form.dataNascimento) {
        this.form.dataNascimento = filters.convertDateOutput(
          this.form.dataNascimento
        );
      }
      if (this.form && this.form.rgDataExpedicao) {
        this.form.rgDataExpedicao = filters.convertDateOutput(
          this.form.rgDataExpedicao
        );
      }

      if (this.form && this.form.telefones && this.form.telefones.length) {
        const numero = this.form.telefones.find(
          ({ preferencial }) => preferencial
        );
        if (numero) {
          this.form.telefoneCelular = numero.numero;
        }
        const telOpcional = this.form.telefones.filter(
          ({ preferencial }) => !preferencial
        );
        if (telOpcional) {
          for (let index = 0; index < telOpcional.length; index++) {
            const telefone = telOpcional[index];
            if (telefone) {
              this.telefonesOpcionais.push({
                tipoTelefoneOpcional: telefone.tipo,
                telefoneOpcional: telefone.numero,
                hasEdit:
                  (this.propostaState.status || "") === "EM_DIGITACAO" ||
                  (this.propostaState.etapa || "") === "adendo_recusado",
              });
            }
          }
        }
      }

      if (this.form && this.form.campoCarteirinha) {
        this.form.confirmarOperadoraAnterior = "Sim";
      }

      if (this.form && this.form.enderecos) {
        const enderecosResidencial = this.form.enderecos.find(
          (e) => e.tipo.findIndex((i) => i === "ENDERECO_RESIDENCIAL") >= 0
        );

        this.enderecoResidencial = enderecosResidencial || {};

        if (this.enderecoResidencial.estado) {
          this.habilitaUf = true;
        }

        if (this.enderecoResidencial.cidade) {
          this.habilitaCidade = true;
        }

        if (this.enderecoResidencial.bairro) {
          this.habilitaBairro = true;
        }

        if (this.enderecoResidencial.logradouro) {
          this.habilitaLogradouro = true;
        }

        const enderecosComercial = this.form.enderecos.find(
          (e) => e.tipo.findIndex((i) => i === "ENDERECO_COMERCIAL") >= 0
        );
        if (enderecosComercial && enderecosComercial.cep) {
          this.possuiEnderecoComercial = true;
          this.removeEnderecoComercial = true;
        }
        this.enderecoComercial = enderecosComercial || {};
        const residencialIndex = this.form.enderecos.findIndex(
          (i) =>
            i.tipo.findIndex((e) => e === "ENDERECO_COBRANCA") >= 0 &&
            i.tipo.findIndex((e) => e === "ENDERECO_RESIDENCIAL") >= 0
        );

        const comercialIndex = this.form.enderecos.findIndex(
          (i) =>
            i.tipo.findIndex((e) => e === "ENDERECO_COBRANCA") >= 0 &&
            i.tipo.findIndex((e) => e === "ENDERECO_COMERCIAL") >= 0
        );

        if (residencialIndex >= 0) {
          this.enderecoCobranca = "residencial";
        } else if (comercialIndex >= 0) this.enderecoCobranca = "comercial";
        else this.enderecoCobranca = "";
      }

      if (this.form && this.form.possuiPlano !== undefined) {
        this.form.reducaoCarencia = this.form.possuiPlano ? "Sim" : "Não";
      }

      if (
        this.dadosComplementaresOperadora &&
        (this.propostaState.plano.categoria ||
          this.propostaState.plano.patrocinadora ||
          this.propostaState.plano.matricula ||
          this.propostaState.plano.nomePai)
      ) {
        this.dadosComplementaresOperadora.categoria =
          this.propostaState.plano.categoria;
        this.dadosComplementaresOperadora.patrocinadora =
          this.propostaState.plano.patrocinadora;
        this.dadosComplementaresOperadora.matricula =
          this.propostaState.plano.matricula;
        this.dadosComplementaresOperadora.nomePai =
          this.propostaState.plano.nomePai;
      }
    },
    setEnderecoCobranca(tipo) {
      const residencialIndex = this.form.enderecos.findIndex(
        (e) => e.tipo.findIndex((i) => i === "ENDERECO_RESIDENCIAL") >= 0
      );

      const comercialIndex = this.form.enderecos.findIndex(
        (e) => e.tipo.findIndex((i) => i === "ENDERECO_COMERCIAL") >= 0
      );

      this.form.enderecos = this.form.enderecos.map((e) => {
        const tipoIndex = e.tipo.findIndex((t) => t === "ENDERECO_COBRANCA");
        if (tipoIndex >= 0) {
          e.tipo.splice(tipoIndex, 1);
        }
        return e;
      });

      if (tipo === "residencial") {
        this.form.enderecos[residencialIndex].tipo.push("ENDERECO_COBRANCA");
        this.enderecoCobranca = "residencial";
      } else {
        this.form.enderecos[comercialIndex].tipo.push("ENDERECO_COBRANCA");
        this.enderecoCobranca = "comercial";
      }
    },
    async successCep(tipo, data) {
      if (tipo === "residencial") {
        this.loadingMountedCepResidencial = false;
        this.enderecoResidencial.estado = data.estado;
        this.enderecoResidencial.cidade = data.cidade;
        this.enderecoResidencial.bairro = data.bairro;
        this.enderecoResidencial.logradouro = data.logradouro;
      } else {
        if (data.estado !== this.enderecoResidencial.estado) {
          this.enderecoComercial = {};
          this.showModalDivergenciaUF = true;
          return;
        }
        this.loadingMountedCepComercial = false;
        this.enderecoComercial.estado = data.estado;
        this.enderecoComercial.cidade = data.cidade;
        this.enderecoComercial.bairro = data.bairro;
        this.enderecoComercial.logradouro = data.logradouro;
      }
    },
    async removeEndereco(comercialIndex) {
      try {
        const endereco = this.form.enderecos[comercialIndex];
        const payload = {
          titular: {
            enderecos: [
              {
                ...endereco,
                tipo: "ENDERECO_COMERCIAL",
                cep: filters.removeSymbols(endereco.cep),
              },
            ],
          },
        };
        await propostaService.removeDados({
          guidProposta: this.propostaState.guid,
          payload,
        });
        this.removeEnderecoComercial = false;
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();

      let paginaEspecifica;

      if (!isValid) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Verifique o preenchimento do formulário e tente novamente",
        });
        return;
      }

      if (this.form.declaracaoNascidoVivo) {
        const pattern = "0123456789012345678910";
        if (
          pattern.indexOf(
            filters.removeSymbols(this.form.declaracaoNascidoVivo)
          ) !== -1
        ) {
          this.$root.$snackBar.open({
            color: "error",
            message: "DNV não pode conter números sequenciais.",
          });
          return;
        }
        if (
          filters.removeSymbols(this.form.declaracaoNascidoVivo).length !== 11
        ) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Preencha o DNV com todos os números corretamente.",
          });
          return;
        }
        if (
          filters
            .removeSymbols(this.form.declaracaoNascidoVivo)
            .split("")
            .every((char) => char === this.form.declaracaoNascidoVivo[0])
        ) {
          this.$root.$snackBar.open({
            color: "error",
            message: "O DNV não pode possuir números iguais.",
          });
          return;
        }
      }

      if (this.form.pisPasep) {
        const pattern = "0123456789012345678910";
        if (pattern.indexOf(filters.removeSymbols(this.form.pisPasep)) !== -1) {
          this.$root.$snackBar.open({
            color: "error",
            message: "PIS não pode conter números sequenciais.",
          });
          return;
        }
        if (filters.removeSymbols(this.form.pisPasep).length !== 11) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Preencha o PIS com todos os números corretamente.",
          });
          return;
        }
        if (
          filters
            .removeSymbols(this.form.pisPasep)
            .split("")
            .every((char) => char === this.form.pisPasep[0])
        ) {
          this.$root.$snackBar.open({
            color: "error",
            message: "O PIS não pode possuir números iguais.",
          });
          return;
        }
      }

      if (
        this.form.telefoneOpcional &&
        this.form.telefoneCelular === this.form.telefoneOpcional
      ) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Telefone Opcional não pode ser igual o telefone principal.",
        });
        return;
      }

      if (this.form.rg && validaService.validaRG(this.form.rg)) {
        this.$root.$snackBar.open({
          color: "error",
          message: "O RG não pode conter caracteres especiais.",
        });
        return;
      }

      const form = { ...this.form }; // Clone do form
      if (form.reducaoCarencia === "Não") {
        form.campoCarteirinha = "";
      }

      // Prepara rgDataExpedicao
      if (form.rgDataExpedicao) {
        form.rgDataExpedicao = filters.convertDateInput(form.rgDataExpedicao);
      }
      // Prepara dataNascimento
      form.dataNascimento = filters.convertDateInput(form.dataNascimento);

      // Prepara telefone
      form.telefones = [];
      if (form.telefoneCelular !== "" && form.telefoneCelular) {
        const preferencial = this.idade >= 18 || false;
        form.telefones = [
          {
            tipo: "CELULAR",
            numero: filters.removeSymbols(form.telefoneCelular),
            preferencial,
          },
        ];
      }

      if (this.telefonesOpcionais) {
        for (let index = 0; index < this.telefonesOpcionais.length; index++) {
          const telefone = this.telefonesOpcionais[index];
          if (telefone) {
            if (
              this.hasAddTelefoneOpcional &&
              telefone.hasEdit &&
              !telefone.telefoneOpcional
            ) {
              this.$root.$snackBar.open({
                color: "error",
                message: `Preencha obrigatoriamente mais um número de telefone opcional.`,
              });
              return;
            }

            const hasExist = form.telefones.find(
              (item) =>
                item.numero === filters.removeSymbols(telefone.telefoneOpcional)
            );
            if (hasExist) {
              this.$root.$snackBar.open({
                color: "error",
                message: `O número informado (${telefone.telefoneOpcional}) já está cadastrado nesta proposta, por favor, insira outro telefone para contato`,
              });
              return;
            }

            if (
              telefone.tipoTelefoneOpcional &&
              telefone.tipoTelefoneOpcional !== "SEM TELEFONE"
            ) {
              form.telefones.push({
                tipo: telefone.tipoTelefoneOpcional,
                numero: filters.removeSymbols(telefone.telefoneOpcional),
                preferencial: false,
              });
            }
          }
        }

        if (this.hasAddTelefoneOpcional) {
          const guidProposta = this.propostaState.guid;
          await propostaService.setdevolucaoPorTentativaContato(guidProposta);
        }
      }

      // Prepara Redução de Carência
      if (form.reducaoCarencia === "Sim") {
        form.possuiPlano = true;
      } else {
        form.possuiPlano = false;
      }

      if (form.tempoPlanoEmMesesAnterior) {
        form.tempoPlanoEmMesesAnterior = parseInt(
          form.tempoPlanoEmMesesAnterior,
          10
        );
      }

      // Prepara endereço
      const enderecosResidencialIndex = form.enderecos.findIndex(
        (e) => e.tipo.findIndex((i) => i === "ENDERECO_RESIDENCIAL") >= 0
      );
      if (enderecosResidencialIndex >= 0) {
        form.enderecos[enderecosResidencialIndex] = {
          ...form.enderecos[enderecosResidencialIndex],
          ...this.enderecoResidencial,
        };
      }

      const enderecosComercialIndex = form.enderecos.findIndex(
        (e) => e.tipo.findIndex((i) => i === "ENDERECO_COMERCIAL") >= 0
      );
      if (enderecosComercialIndex >= 0) {
        form.enderecos[enderecosComercialIndex] = {
          ...form.enderecos[enderecosComercialIndex],
          ...this.enderecoComercial,
        };
      }

      const possuiEnderecoCobrancaIndex = form.enderecos.findIndex(
        (e) => e.tipo.findIndex((i) => i === "ENDERECO_COBRANCA") >= 0
      );

      if (possuiEnderecoCobrancaIndex < 0) {
        form.enderecos[enderecosResidencialIndex].tipo.push(
          "ENDERECO_COBRANCA"
        );
      }

      for (let index = 0; index < form.enderecos.length; index++) {
        form.enderecos[index].cep = filters.removeSymbols(
          form.enderecos[index].cep
        );
      }
      if (
        this.propostaState.corretor.tipoCorretor == "Externo" &&
        this.propostaState.plano.operadora == "Unimed BH"
      ) {
        this.setDadosSumula({
          possuiSumula: form.sumula == "Sim" ? true : false,
          sumulaPlanoAnterior: form.sumulaPlanoAnterior,
        });
      }
      form.cpf = filters.removeSymbols(form.cpf);
      form.rg = filters.removeSymbols(form.rg);
      form.pisPasep = filters.removeSymbols(form.pisPasep);
      form.cns = filters.removeSymbols(form.cns);
      if (this.dnvObrigatorio) {
        form.declaracaoNascidoVivo = filters.removeSymbols(
          form.declaracaoNascidoVivo
        );
      } else {
        form.declaracaoNascidoVivo = "";
      }

      if (this.dadosProfissionaisState) {
        this.dadosProfissionais = this.dadosProfissionaisState;
      }

      if (
        this.dadosComplementaresOperadora.matricula ||
        this.dadosComplementaresOperadora.patrocinadora ||
        this.dadosComplementaresOperadora.categoria ||
        this.dadosComplementaresOperadora.nomePai
      ) {
        this.setPlano({
          ...this.propostaState.plano,
          dadosComplementares: this.dadosComplementaresOperadora,
        });
      }

      if (
        form.itemCarenciaAnterior &&
        form.itemCarenciaAnterior.codigoCarencia
      ) {
        this.setDadosTitular({
          ...form,
          codigoCarencia: form.itemCarenciaAnterior.codigoCarencia,
          descricaoCodigoCarencia:
            form.itemCarenciaAnterior.descricaoCodigoCarencia,
        });
      } else {
        this.setDadosTitular({ ...form });
      }

      if (this.dadosProfissionais) {
        this.setDadosProfissionais({ ...this.dadosProfissionais });
      }

      if (
        this.blnContinuarState.isEditing ||
        this.blnContinuarState.isEditingRL
      ) {
        localStorage.setItem("etapaBeforeReset", this.propostaState.etapa);
        const idade = filters.getAge(new Date(form.dataNascimento));
        const etapaDevolvida =
          this.propostaState.status.toUpperCase() === "DEVOLVIDA" || false;
          const cpfAntigo = this.propostaState.cpf;
        if (idade < 18) {
          const etapa = etapaDevolvida
            ? this.propostaState.etapa
            : "dados_responsavel_contratacao";
          this.setDadosProposta({ ...this.propostaState, etapa });
          this.setBlnContinuar({
            flag: true,
            page: 5,
            isEditingRL: true,
            isEditingCPFTitular: cpfAntigo !== form.cpf,
            isEditingPlan: this.blnContinuarState.isEditingPlan,
          });
        } else {
          await this.removerResponsavelLegal(this.propostaState);

          // Se ele mudou o CPF, é obrigado a ir para os dados financeiros novamente para verificar a isenção de taxa
          if (cpfAntigo !== form.cpf) {
            this.setDadosProposta({
              ...this.propostaState,
              etapa: !etapaDevolvida ? "dados_financeiros" : undefined,
            });
          } else {
            this.setDadosProposta({ ...this.propostaState });
          }
        }
      } else {
        const etapa =
          filters.getAge(new Date(form.dataNascimento)) < 18
            ? "dados_responsavel_contratacao"
            : "dados_profissionais";
        await this.removerResponsavelLegal(this.propostaState);
        this.setDadosProposta({ ...this.propostaState, etapa });
      }

      try {
        this.loadingSalvar = true;
        await this.salvarDados();
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message:
            error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      } finally {
        this.loadingSalvar = false;
      }
    },
    setCarencia() {
      if (
        this.form.reducaoCarencia == null ||
        this.form.reducaoCarencia === ""
      ) {
        this.$set(this.form, "reducaoCarencia", "");
      }
    },
    async salvarDados() {
      const [data] = await propostaService.addOrEdit(this.propostaState.guid, {
        ...this.propostaState,
      });
    },
    async removerResponsavelLegal() {
      if (
        !this.propostaState ||
        !this.propostaState.responsavelLegal ||
        !this.propostaState.responsavelLegal.guid
      )
        return;
      const payload = {
        responsavelLegal: {},
      };
      await propostaService.removeDados({
        guidProposta: this.propostaState.guid,
        payload,
      });
    },
    validacaoCPFTitular(cpf) {
      if (cpf && cpf.length === 14) {
        cpf = filters.removeSymbols(cpf);
        if (this.propostaState.dependentes != null) {
          for (
            let cont = 0;
            cont < this.propostaState.dependentes.length;
            cont++
          ) {
            if (cpf === this.propostaState.dependentes[cont].cpf) {
              return "cpf_beneficiario";
            }
          }
        }
      }
      return "required|cpf";
    },
    async setObrigatoriedadePis() {
      try {
        if (this.dadosTitularState.entidade) {
          const data = await regrasService.verificaRegraPis({
            entidade: this.dadosTitularState.entidade.toUpperCase(),
            cpf: this.form.cpf,
          });
          this.pisObrigatorio = data.pisObrigatorio;
          this.form.pisPasep = this.form.pisPasep || data.numeroPis;
        }
      } catch (error) {
        this.pisObrigatorio = false;
        this.form.pisPasep = this.form.pisPasep || "";
      }
    },
    handleOkButtonModalDivergenciaUF() {
      this.showModalDivergenciaUF = false;
    },
  },
};
// 744 - html 642
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
