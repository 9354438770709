var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "validation-observer",
        { ref: "formTaxa", staticClass: "taxa-angariacao" },
        [
          _vm.mostrarOpcoesTaxa ||
          _vm.taxaAdesao.cobrarTaxa ||
          ((_vm.propostaState.status || "").toUpperCase() !== "EM_DIGITACAO" &&
            _vm.form.taxaFormatada)
            ? _c(
                "div",
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "primary--text mb-2 subtitle-1 font-weight-bold",
                    },
                    [_vm._v(" Taxa de angariação ")]
                  ),
                  _c("v-divider"),
                ],
                1
              )
            : _vm._e(),
          _vm.mostrarOpcoesTaxa
            ? _c(
                "div",
                { staticClass: "tipo-taxa" },
                [
                  _c("validation-provider", {
                    attrs: {
                      name: "Taxa de Angariação",
                      vid: "taxaAngariacao",
                      slim: "",
                      rules: "required",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: {
                                    mandatory: false,
                                    "error-messages": errors,
                                  },
                                  model: {
                                    value: _vm.form.tipoTaxaAngariacao,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "tipoTaxaAngariacao",
                                        $$v
                                      )
                                    },
                                    expression: "form.tipoTaxaAngariacao",
                                  },
                                },
                                _vm._l(_vm.tiposTaxa, function (forma, index) {
                                  return _c(
                                    "div",
                                    { key: `taga-angariacao-${index}` },
                                    [
                                      _c("v-radio", {
                                        staticClass: "mb-4 mt-4",
                                        attrs: {
                                          "data-test-id": "taxa-" + index,
                                          label: forma.nome,
                                          value: forma.id,
                                          disabled: _vm.desabilitarTaxa,
                                        },
                                      }),
                                      index === 0 ? _c("v-divider") : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3048021999
                    ),
                  }),
                  _vm.verificarJustificativa
                    ? _c(
                        "div",
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "primary--text mb-2 subtitle-1 font-weight-bold",
                            },
                            [_vm._v(" Justificativa Isenção ")]
                          ),
                          _c("v-divider"),
                          _c("validation-provider", {
                            attrs: {
                              name: "Taxa de Angariação",
                              vid: "taxaAngariacao",
                              slim: "",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            mandatory: false,
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .tipoIsencaoTaxaAngariacao,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "tipoIsencaoTaxaAngariacao",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.tipoIsencaoTaxaAngariacao",
                                          },
                                        },
                                        _vm._l(
                                          _vm.tiposIsencao,
                                          function (forma, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: `taga-angariacao-${index}`,
                                              },
                                              [
                                                _c("v-radio", {
                                                  staticClass: "mb-4 mt-4",
                                                  attrs: {
                                                    "data-test-id":
                                                      "taxa-" + index,
                                                    label: forma.nome,
                                                    value: forma.id,
                                                    disabled:
                                                      _vm.desabilitarTaxa,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              139272196
                            ),
                          }),
                          _vm.form.tipoIsencaoTaxaAngariacao == "OUTROS"
                            ? _c("validation-provider", {
                                attrs: {
                                  name: "Justificativa de Isenção",
                                  vid: "justificativaTaxaAngariacao",
                                  slim: "",
                                  rules: "required|max:300",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("v-textarea", {
                                            attrs: {
                                              mode: "aggressive",
                                              "data-test-id":
                                                "justificativaTaxaAngariacao",
                                              "error-messages": errors,
                                              id: "justificativaTaxaAngariacao",
                                              name: "justificativaTaxaAngariacao",
                                              counter: "300",
                                              label: "Justificativa da isenção",
                                              clearable: "",
                                              outlined: "",
                                              "no-resize": "",
                                            },
                                            on: {
                                              keydown: _vm.limitaCaracteres,
                                              input:
                                                _vm.limitaInputJustificativa,
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .justificativaTaxaAngariacao,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "justificativaTaxaAngariacao",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.justificativaTaxaAngariacao",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1938496393
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.exibirMensagem
            ? _c("div", { staticClass: "text-center text-caption" }, [
                _vm._v(" " + _vm._s(_vm.mesagemIsencao) + " "),
              ])
            : _vm._e(),
          (_vm.taxaAdesao.cobrarTaxa &&
            _vm.form.tipoTaxaAngariacao === "COBRANCA") ||
          (_vm.propostaState.status || "").toUpperCase() !== "EM_DIGITACAO"
            ? _c("div", [
                _vm.taxaAdesao.valorTaxa
                  ? _c(
                      "p",
                      {
                        staticClass: "caption text-center mt-4",
                        staticStyle: { "line-height": "1.3" },
                      },
                      [
                        _c("span", { staticClass: "corretor-nome" }, [
                          _vm._v(" " + _vm._s(_vm.corretorNome) + ", "),
                        ]),
                        _vm._v(
                          " o valor da taxa de adesão para esse contrato é de "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.taxaAdesao.valorTaxa.max)
                            )
                          ),
                        ]),
                        _c("br"),
                        _vm._v("o valor mínimo que pode ser cobrado é de "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.taxaAdesao.valorTaxa.min)
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.taxaAdesao.valorTaxa && !_vm.propostaConcluida
                  ? _c(
                      "div",
                      { staticClass: "taxa-slider mt-10" },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center primary--text mb-10" },
                          [
                            _c("strong", [
                              _vm._v("Seleciona na barra o valor de desconto"),
                            ]),
                          ]
                        ),
                        _c("v-slider", {
                          attrs: {
                            max: _vm.taxaAdesao.valorTaxa.porcentagemMax,
                            "thumb-label": "always",
                          },
                          on: { change: _vm.taxaFinal, input: _vm.taxaFinal },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "thumb-label",
                                fn: function ({ value }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "taxa-slider__porcentagem",
                                      },
                                      [_vm._v(_vm._s(value) + "%")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3043642216
                          ),
                          model: {
                            value: _vm.form.valueSlider,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "valueSlider", $$v)
                            },
                            expression: "form.valueSlider",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.taxaAdesao.cobrarTaxa &&
                _vm.form.tipoTaxaAngariacao === "COBRANCA"
                  ? _c(
                      "div",
                      [
                        _c("base-text-field", {
                          staticClass: "mt-4",
                          attrs: {
                            outlined: "",
                            label: "Valor da Taxa",
                            id: "taxa-adesao-formatada",
                            name: "taxa-adesao-formatada",
                            type: "text",
                            disabled: true,
                          },
                          model: {
                            value: _vm.form.taxaFormatada,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "taxaFormatada", $$v)
                            },
                            expression: "form.taxaFormatada",
                          },
                        }),
                        _c("validation-provider", {
                          attrs: {
                            rules: _vm.taxaAdesao.valorTaxa
                              ? `required|min_value:0|max_value:${_vm.taxaAdesao.valorTaxa.porcentagemMax}`
                              : null,
                            mode: "aggressive",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.valueSlider,
                                          expression: "form.valueSlider",
                                        },
                                      ],
                                      attrs: {
                                        type: "hidden",
                                        "data-vv-validate-on": "change",
                                        name: "taxa de adesão",
                                        id: "taxa-adesao",
                                      },
                                      domProps: { value: _vm.form.valueSlider },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form,
                                            "valueSlider",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-messages v-messages__message error--text mt-n3",
                                      },
                                      [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            736851564
                          ),
                        }),
                        _c("br"),
                        _c("validation-provider", {
                          attrs: {
                            rules: _vm.taxaAdesao.valorTaxa
                              ? `required|min_value:${_vm.taxaAdesao.valorTaxa.min}|max_value:${_vm.taxaAdesao.valorTaxa.max}`
                              : `required`,
                            mode: "aggressive",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.valorTaxaAdesao,
                                          expression: "form.valorTaxaAdesao",
                                        },
                                      ],
                                      attrs: {
                                        type: "hidden",
                                        "data-vv-validate-on": "change",
                                        name: "taxa de adesão",
                                        id: "taxa-adesao",
                                      },
                                      domProps: {
                                        value: _vm.form.valorTaxaAdesao,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form,
                                            "valorTaxaAdesao",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-messages v-messages__message error--text mt-n3",
                                      },
                                      [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3684306380
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }